import React ,{ useState ,useEffect} from "react";
import {db,timestamp} from '../../firebase';
import { addDoc, arrayUnion, setDoc, doc, getDoc, increment, runTransaction,updateDoc,Timestamp } from "@firebase/firestore";
import Hashids from 'hashids'
import { stringify } from "@firebase/util";


async function AddClass(data,branch,reference){
    const hashids = new Hashids('',5)
    let error = ''
    let success='';
    let code="";
    
    
    
        //references
        const createdAt = timestamp;
        
        // Create a reference to the SF doc.
        var branchRef = doc(db,"branches",branch.id)
        var courseRef = doc(db,"courses",data.programId)
        var logRef = doc(db,"log",'all')
        var countRef = doc(db,"MC","count")
       

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(branchRef);
                const courseDoc = await transaction.get(courseRef);
                const countDoc = await transaction.get(countRef);

                if (!sfDoc.exists()) {
                  throw "Branch does not exist!";
                }
                if (!courseDoc.exists()) {
                  throw "Course does not exist!";
                }

                var newCount = (countDoc.data().classNumber||0) + 1;
                console.log(newCount)
                code=hashids.encode(newCount); 
            
                transaction.update(branchRef, { 
                    classcount:increment(1),
                   classes: arrayUnion(code)
                 });
                transaction.update(courseRef, { 
                    classcount:increment(1),
                   classes: arrayUnion(code)
                 });
                transaction.update(countRef, { 
                  classNumber:newCount,
                 });
                transaction.set(doc(db,"classes",code), { 
                    ...data,
                    locationId:branch.id,
                    courseId:data.programId,
                    course:courseDoc.data().name,
                    closed: true, // for testing sake
                    id:code,
                    class:[],
                    location:branch,
                    minage:parseInt(data.minage),
                    order: parseInt(data.datefororder.replace(/\\/g, '').replaceAll('-', '')),
                    createdAt
                 });
                

              });
              // console.log(timestamp.fromDate(data.date))
              console.log("Session Created successfully!");
              success = true;
            
            } catch (e) {
              console.log("Registration failed: ", e);
              error = "Registration failed! ";
             
              setDoc(doc(db,"log",data.date.replace(/\\/g, '')), {
                createdAt,
                log: "Registration failed",
                details : stringify(e),
                ...data

            });
            }

       
    return {error,success}

}

export default AddClass;