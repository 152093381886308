import React, { useState, useEffect,useRef } from "react";
import DeleteUser from "../Hooks/useDeleteUser";
import RenewHubUser from "../Hooks/useRenewHub";
import RenewHubUserForm from "../Forms/renewHubForm";
import { Container, Row, Button, Form, Alert, Tabs, Tab, ListGroup , Spinner, Col} from "react-bootstrap";
import { collection, doc, getDoc, query, where,toDate } from "firebase/firestore";
import { db } from "../../firebase";
import { format, isPast, parseISO } from "date-fns";
import { useHubContext } from '../Context/HubContext';
import RenewHubUserFormAdmin from "../Forms/renewHubFormAdmin";
import DeleteHubUser from "../Hooks/useDeleteHubUser";

export default function ViewHub({data}) {
  const idRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [key,setKey] = useState('home')
  const {setCurrentHub,currentHub} =useHubContext()
  const [success, setSuccess] = useState(false);

  setCurrentHub(data);

  // Date variables
  let dateExpired = new Date(data.expiryDate.toDate().toString());


  // let { from } = location.state || { from: { pathname: "/dashboard" } };
  const GoDeleteUser = async(userID,classID)=>{
    // console.log(userID);
    setLoading(true)

    const {error,success} = await DeleteHubUser(userID,classID);

    setError(error);
    setSuccess(success);
    setLoading(false)
}

  async function handleSubmit(e) {
    e.preventDefault();

   
    // const fetchstudentsData =async(classData)=>{
    //   console.log("--->",classData.current.value );
    //       setLoading(true)
    //       let q = ''
       
    //          q = doc(db, "Hubstudents",classData.current.value );
             

    //      //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
    //          const  docSnap = await getDoc(q);
         
    //          if (docSnap.exists()) {
    //           console.log("Document data:", docSnap.data());
    //           setData(docSnap.data());
    //           setCurrentHub(docSnap.data());
    //         } else {
    //           // doc.data() will be undefined in this case
    //           console.log("No such document!");
    //           setError("No such document!")
    //         }

         
    //      //  splitsession(classesdata);
    //      setLoading(false)

    //     }

   

    setLoading(false);
  }
  return (
    <Container fluid>
      <Row>
        {success && <Alert variant="success" data-toggle  dismissible>
            <h3 className="mx-auto">{"Student deleted Succesfuly"}</h3>
            </Alert> }
            
            {
            (loading)   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              
              </div>

        }
      <Container >
        <Row>
          <Col sm={12} md={9} lg={8} className="mx-auto mb-2">
          <Tabs 
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
             className="mb-5">
            <Tab eventKey="home" title="Profile">
            <h6  className={isPast(dateExpired) ? 'text-danger text-center' : 'text-info text-center'} >
                Your current membership {isPast(dateExpired) ? 'expired' : 'expires'}  on {format(dateExpired,"do-MMMM-yyyy")}
                <br/>
                {isPast(dateExpired) &&  <button className="btn btn-info text-light jnow mx-auto my-3" onClick={()=>setKey('renew')}>Renew</button>}
              </h6>
              <ListGroup variant="flush">
                <ListGroup.Item>Name : {data.firstname}  {data.othername} {data.lastname}</ListGroup.Item>
                <ListGroup.Item>User ID : {data.id}</ListGroup.Item>
                
                <ListGroup.Item>Hub Since - {new Date(data.createdAt.toDate().toString()).toDateString("en-US")}</ListGroup.Item>
                
                <ListGroup.Item  className="text-capitalize fw-bolder bg-info text-white text-center">Current Subscription</ListGroup.Item>
                <ListGroup.Item>Start Date - {new Date(data.startDate.toDate().toString()).toDateString("en-US")}</ListGroup.Item>
                <ListGroup.Item className={isPast(dateExpired) ? 'bg-danger text-white' : 'bg-warning'} >Expiry Date - {new Date(data.expiryDate.toDate().toString()).toDateString("en-US")}</ListGroup.Item>
                
              </ListGroup>
            </Tab>
            <Tab eventKey="renew" title="Renew Subscription">
              <RenewHubUserFormAdmin details={data}/>
            </Tab>
            <Tab eventKey="delete" title="Delete student">
            <Button  onClick={()=>GoDeleteUser(data.id,data.hub.id)} className="my-auto btn my-auto mx-auto"> Delete Student {data.id}</Button>
            </Tab>
            
           
          </Tabs>
        </Col>
        </Row>
      </Container> 
     
      </Row>
    </Container>
  );
}