import { db } from "../../firebase";
import { collection, getDocs, query, where, deleteDoc, doc } from "@firebase/firestore";

// Function to get the list of classes
async function getClassList() {
  try {
    const classesRef = collection(db, "SATclasses");
    const classSnapshot = await getDocs(classesRef);
    const classList = classSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    return classList;
  } catch (error) {
    console.error("Error fetching class list: ", error);
    return [];
  }
}

// Function to get the list of students based on the selected class
async function getStudentsByClass(classId) {
  try {
    const studentsRef = collection(db, "SATstudents");
    const q = query(studentsRef, where("session", "==", classId));
    const studentSnapshot = await getDocs(q);
    const studentList = studentSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    return studentList;
  } catch (error) {
    console.error("Error fetching students list: ", error);
    return [];
  }
}

// Function to delete a student by their ID
async function deleteStudent(studentId) {
  try {
    const studentDocRef = doc(db, "SATstudents", studentId);
    await deleteDoc(studentDocRef);
    console.log(`Student with ID ${studentId} has been deleted`);
  } catch (error) {
    console.error("Error deleting student: ", error);
  }
}

export { getClassList, getStudentsByClass, deleteStudent };
