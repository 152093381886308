import React, { useState, useEffect } from "react";
import { ListGroup, Button,Tab,Tabs,Dropdown, Col, Spinner } from "react-bootstrap";
import DeleteUser from "../Hooks/useDeleteUser";
// import Paymentresults from "../helpers/MiniComponents/Payments";
// import Productresults from "../helpers/MiniComponents/Products";
// import Transactionresults from "../helpers/MiniComponents/Transactions";
// import PaymentTable from "../helpers/Tables/PaymentTable";
// // import {  } from "rsuite";
// import ProductTable from "../helpers/Tables/ProductTable";
// import TransactionTable from "../helpers/Tables/TransactionTable";
// import useFetchProductByUserId from "../Hooks/useFetchProductsByUserId";
// import useFetchTransactionByUserId from "../Hooks/useFetchTransactionsByUserId";

const PartnerViewUser = ({ data }, props) => {
  const [user, setUser] = useState(data);
    const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");  
  const closeModal = () => {
    setShow(false);
        

  };

  
  


  return (
  <div>
    {loading ? 
    <Spinner animation="grow" variant="primary"/>
    :
    <div>
      <Tabs defaultActiveKey="UserInfo" id="uncontrolled-tab-example" className="mb-3">
       
       <Tab eventKey="UserInfo" title="Student details">
        <ListGroup>
          <ListGroup.Item>Student Name : {user.firstname} {user.lastname}</ListGroup.Item>
          <ListGroup.Item>Email : {user.email}</ListGroup.Item>
          <ListGroup.Item>School : {user.school}</ListGroup.Item>
          <ListGroup.Item>Phone : {user.contact}</ListGroup.Item>
          <ListGroup.Item>Date of Birth : {user.dob}</ListGroup.Item>
          <ListGroup.Item>Date of Class : {user.class.date}</ListGroup.Item>
          <ListGroup.Item>Session : {user.class.session}</ListGroup.Item>
          <ListGroup.Item>Branch : {user.location && user.location.name} ({user.location && user.location.location})</ListGroup.Item>
        </ListGroup>
       </Tab>
       <Tab eventKey="GInfo" title="Parent Info" >
        <ListGroup>
          <ListGroup.Item>Name : {user.pname}</ListGroup.Item>
          <ListGroup.Item>Email : {user.pemail}</ListGroup.Item>
          <ListGroup.Item>Phone : {user.pcontact}</ListGroup.Item>
          <ListGroup.Item>Address : {user.paddress}</ListGroup.Item>
          <ListGroup.Item>Occupation : {user.poccupation}</ListGroup.Item>
        </ListGroup>       
        </Tab>
     </Tabs>
     
      
    </div>
  }

    </div>
  );
};

export default PartnerViewUser;
