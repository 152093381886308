import React, { useState } from 'react';
import { Row,Container,Col,Button } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";

//visible header that contains LOGO and Navigation Icon
import "./Info.css";
import "../../assets/css/custom.css";
import ViewModal from '../../modal/viewModal';
import AddSATUserForm from '../../Component/Forms/addSATUserForm';
import AddIELTSUserForm from '../../Component/Forms/addIELTSUserForm';
import IELTSlogo from '../../Component/BCLOGO/IELTSlogo';

const IELTSBanner=()=> {
	const [show1,setShow1] = useState(false);
	const [data,setData] = useState('');

    return(
		<section className="sessions iELTS py-5 bg-danger" id='IELTS'>

		<div className="sessions  py-5  secPadding" id='IELTS' >
		<div className="container">
			<div className="row">
				<div className="mx-auto  text-white">
					<h1 className="text-center text-white">IELTS Prep Class</h1>
<Row>					
<Col md={7} className="mx-auto text-white" >

					<p className="text- h5">
						If you’re looking to work or study abroad, in an 
						English-speaking country, you will need to pass an English Proficiency test.
					</p>
					<p className="text- h5">
						IELTS is the only English Proficiency Test that is accepted by immigration authorities in Australia,Canada, New Zealand, the United States and the United Kingdom. 
					</p>
					<p className="text- h5">
						Join our IELTS prep class and let us help you ace the IELTS to begin your academic or professional journey in any IELTS accepted country. 
					</p>
					<p className="text- h5">
					<span className='fw-bold text-warning'>We are a certified IELTS Registration centre for the British Council.</span>
					
					</p>
					
					<IELTSlogo/>
						<br></br>
					
</Col>
<Col md={4} lg={4} className="mx-auto text-black" >

					<div className="Row text-primary">

					<div className='card mb-5 mb-lg-0' >
					<div className="card-body">
						{/* <h6 className="card-title  text-capitalize text-center">Prep Registration Fee (Non-refundable) </h6> */}
						{/* <h5 className="card-title text-primary fw-bolder text-uppercase text-center">Enroll For a 23.5% Discount</h5>
						<h6 className="card-title text-center text-decoration-line-through text-danger"><span className="period">GH₵850</span></h6>    */}
						{/* <h6 className="card-price text-primary text-center ">{"GH₵2000"} <span className="period">only</span></h6>    */}
					
							
						{/* <hr/> */}
						<h5 className="card-title  text-center text-capitalize ">Prep Class Fee </h5>
						<h6 className="card-price text-primary text-center ">{"GH₵2000"} <span className="period">only</span></h6>   
						
						<hr/>
						<h5 className="card-title  text-center text-capitalize ">IELTS Test Fee (UKVI) </h5>
						<h6 className="card-price text-primary text-center ">{"GH₵3570"} <span className="period">only</span></h6>   
						<hr/>

						<h5 className="card-title  text-center text-capitalize ">IELTS Test Fee (Standard) </h5>
						<h6 className="card-price text-primary text-center ">{"GH₵3360"} <span className="period">only</span></h6>   
						<hr/>

						<ul className="fa-ul">
								
						</ul>
						<div className="d-grid">
						<Button  onClick={()=>setShow1(true)} className="btn btn-primary text-uppercase fw-bolder">Enroll Now</Button>
						
						
						</div>


					</div>
        </div>
        
      </div>
</Col>
</Row>

	  <p className='text-center'>
					{/* <a className="btn btn-light text-primary fw-bolder text-uppercase mx-auto" onClick={()=>setShow1(true)} > Register Now!</a> */}
              {/* <Button target='_blank' href='https://forms.gle/BpdtggrPkjkp9eGn6' className="btn btn-light text-primary fw-bolder mx-auto text-uppercase">Enroll Now</Button> */}

					</p>
					<div className="d-grid">
              
              <ViewModal
												handleClose={() => setShow1(false)}
												show={show1}
												button="close"
												title={`IELTS Prep Class`}>
												<div className="row">	
												<AddIELTSUserForm type={data} />
											</div>
											</ViewModal>
            </div>
					</div>
			</div>
		</div>
	</div>
</section>

    );
}
export default IELTSBanner;











