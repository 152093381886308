import React ,{ useState ,useEffect,useContext} from "react";

const TestContext =  React.createContext();

export function useTestContext() {
    return useContext(TestContext)
};


export const TestContextProvider =({children})=>{
    const [loading, setLoading] = useState(false);
    const [currentTest,setTest] = useState();
    




 const setCurrentTest= async(data)=>{
    setLoading(true);
    setTest(data);

    setLoading(false)

}



const setNewTest= (data)=>{
    setTest(data);

}
  

    const value = {
        currentTest,setCurrentTest,
    }
    return (
        <TestContext.Provider value={value}>
            {!loading && children}
        </TestContext.Provider>
    )
} 