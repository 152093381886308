import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';

// import { useAuth } from '../../Context/AuthContext';
// material ui imports

 import style from 'bootstrap/dist/css/bootstrap.min.css';
 import {PaystackButton} from "react-paystack";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from '@firebase/storage';
import AddUser from '../Hooks/useAddUser';
import { FaLastfmSquare } from 'react-icons/fa';
// import { DropzoneArea } from 'material-ui-dropzone';
import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import IonRangeSlider from 'react-ion-slider'
import { Slider } from '@material-ui/core';
import { nextSaturday,format, parse, addWeeks, parseISO, isDate, isSaturday, isPast } from 'date-fns';
import AddHubUser from '../Hooks/useAddHubUser';
import RenewHubUser from '../Hooks/useRenewHub';
import { async } from '@firebase/util';
import { useHubContext } from '../Context/HubContext';
import { courses } from '../../coursedata';
//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime(); 

const RenewHubUserForm =({details})=>{
     // files to upload
  var price = courses.hub.price;
  const [data, setData] = useState({...details,duration:1,date:'',startdate:'',});
  const [amount, setAmount] = useState(courses.hub.price);
  const [mainError, setMainError] = useState('');
  const [img, setImg] = useState();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success, setSuccess] = useState(false);
  const [paid, setPaid] = useState(false);
  const [checked,setChecked] = useState(false)
  const [avclasses,setAvclasses] = useState()
  const [ location,setLocation] = useState()
  
  const {setCurrentHub,currentHub} =useHubContext()

  // FUNCTIONS FOR SLIDE 
  
  function valuetext(value) {
    return `${value} months`;
  }

  const marks = [
    {
      value: 1,
      label: '1 Month',
    },
    
    {
      value: 3,
      label: '3',
    },
    {
      value: 5,
      label: '5 Months',
    },
    
    {
      value: 10,
      label: '10 ',
    },
    
    {
      value: 12,
      label: '12 Months',
    },
  ];

  const handleChange = (event, newValue) => {
    let val = newValue;
    let nam = 'duration';

    UpdateData(nam, val);
    setAmount(price*val)
  };
  
  // SLIDE FUNCTIONS END HERE
// DATE FUNCTIONS STARTS HERE
      let weeks = data.duration * 4;
      let renewableDate = isPast(new Date(currentHub.expiryDate.toDate().toString())) ? new Date() : new Date(currentHub.expiryDate.toDate().toString());// Date another renew can start
      let thisday = data.startdate ?  parseISO(data.startdate): renewableDate; //curent staart date of renewal
      let dateExpired = new Date(data.expiryDate.toDate().toString());
      const todaystring = thisday.toLocaleDateString("en-US");
      const closestSaturday= nextSaturday(thisday) //saturday of renewal
      const lastSaturday= addWeeks(closestSaturday,weeks) // expiring saturday date
  //

  const ClearProcess =async()=>{
    setLoading(true);
    await fetchstudentsData(data.id)
    setPaid(false);
    setSuccess(false);
  }
  
  const fetchstudentsData =async(classData)=>{
    console.log("--->",classData);
        setLoading(true)
        let q = ''
     
           q = doc(db, "Hubstudents",classData );
           

       //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  docSnap = await getDoc(q);
       
           if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setData(docSnap.data());
            setCurrentHub(docSnap.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setError("No such document!")
          }

       
       //  splitsession(classesdata);
       setLoading(false)

      }
  const UpdateData = (item, value) => {
    setData((data) => ({ ...data, [item]: value }));
    setFormError((formError) => ({ ...formError, [item]: "" }));
   
  };
  const fetchData =async(location)=>{
    console.log('in fetch data '+location)
         setLoading(true)
 
         let classesdata = []
        const q = query(collection(db, "hub"),where("closed", "==", false),where("locationId","==",location));
 //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
       const  querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
         // doc.data() is never undefined for query doc snapshots
         console.log(doc.id, " => ", doc.data());
         classesdata.push(doc.data())
       });
       
       UpdateData("locationId",location)
       setAvclasses(classesdata)
       setLoading(false)
       }
  
  const eventHandler = (event) => {
    let val = event.target.value;
    let nam = event.target.name;
    UpdateData(nam, val);
  };

  
 
  const Submit = async (reference,data) => {
    // Add Product
    const { error, success } = await RenewHubUser(data,reference,closestSaturday,lastSaturday);

    setMainError(error);
    setSuccess(success);
    setLoading(false);
  };

  
useEffect(()=>{
  const fetchLocationData =async()=>{
     setLoading(true)

     let locationData = []
    const q = query(collection(db, "branches"), where("closed", "==", false));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
   const  querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
     // doc.data() is never undefined for query doc snapshots
     console.log(doc.id, " => ", doc.data());
     locationData.push(doc.data())
   });
   if (locationData.length>1){
     setLocation(locationData);
   }else{
     setLocation(locationData);

   }
   
   // splitsession(classesdata);
   setLoading(false)
   }
     fetchLocationData();
},[])

  
  




    // PAYSTACK INTEGRATION

  // SUCCESSFULLY PAID
  const handlePaystackSuccessAction = async(reference) => {
    setLoading(true)
    setError(error);
    setSuccess(success);
    setPaid(true);
    setLoading(false)
    Submit(reference)
    console.log(reference);

  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    
    

  };


  const config = {
    reference: (new Date()).getTime().toString(),
    currency: "GHS",
    email: data.pemail,
    amount: 100*price*data.duration,
    publicKey: "pk_live_2ecef1c81a94e50860200c001677efee909e0a96",
    // publicKey: "pk_test_c926b8bee36c5a4ebd43aa6b68003106cfcee1b8",

  };

  const componentProps = {
    ...config,
    text: "Pay GH₵"+amount,
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: () => handlePaystackCloseAction(),

  };

  const output = (
    <Row>
    <Form.Group className="row" controlId="validationCustom01">
    <Form.Label className="col-sm-12  align-bottom  text-md-start mx-auto my-auto">
      select a renewal date.
    </Form.Label>
    <InputGroup className="form-input col">
      <Form.Control
        className=""
        type="date"
        name="startdate"
        required={true}
        value={format(closestSaturday,"yyyy-MM-dd")}
        // defaultValue={"2021-08-01"}
        onChange={eventHandler}
        isInvalid={!!formError.startdate}
      />
      <Form.Control.Feedback type="invalid">
        {formError.startdate}
      </Form.Control.Feedback>
    </InputGroup>
  </Form.Group>

  <Form.Group className="row my-md-3" controlId="validationCustom01">
    <Form.Label className="  align-bottom  text-md-start mx-auto my-auto">
      Adjust the duration (months)
    </Form.Label>
    
    <InputGroup className="form-input ">
    <Slider 
            aria-label=""
            defaultValue={1}
            size="medium"
            getAriaValueText={()=>valuetext(data.duration)}
            step={1}
            name ={"duration"}
            min = {1}
            max = {12}
            marks ={marks}
            valueLabelDisplay="on"
            value={data.duration}
            onChangeCommitted={handleChange}
            onChange={handleChange}
            />
            
    </InputGroup>
  </Form.Group>

  <Form.Group className="row" controlId="validationCustom01">
    <Form.Label className="col-sm-12 align-bottom text-md-start mx-auto my-auto ">
      New Expiry Date
    </Form.Label>
    <InputGroup className="form-input col">
      <Form.Control
        className=""
        type="date"
        name="exp"
        readOnly
        required={true}
        value={format(lastSaturday,"yyyy-MM-dd")}
        onChange={eventHandler}
        isInvalid={!!formError.dob}
      />
      <Form.Control.Feedback type="invalid">
        {formError.dob}
      </Form.Control.Feedback>
    </InputGroup>
  </Form.Group>
  </Row>
    )

    return(
        <>
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {success && <Alert variant="success" onClose={() => ClearProcess(false)} dismissible>
            <h3 className="mx-auto">{"Renewal Succesfuly"}</h3>
              <span className="mx-auto">Kindly check your mail "{data && data.pemail}" for confirmation and reciepts, Thank you! </span>
            </Alert> }
            
            {
            (paid && !success )   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              
              </div>

        }
            {(!error  && !paid && data)   &&  <Col className="text-center">
            {/* {(!error && !success && !validated)   &&  <Col className="text-center"> */}
              <div className='text-end fst-italic text-danger'>price = GH₵ {amount}.00</div>
              
                <Row className='container mx-auto'>
               
                  { output }
             

                  
                  <span className='fst-italic'>NB: Your selected duration is exaclty {weeks} weeks</span>

                </Row>
              <h6 className="text-warning">Make payment to complete the process</h6>
              <PaystackButton disabled={validated}
                  className="paystack-button mx-auto px-3"
                  {...componentProps}
                />
                                {/* <PaymentHub amount={price} data={data}  Submit={(reference,data)=>Submit(reference,data)}/> */}

        </Col> }

            
        </>
     
    );
}

export default RenewHubUserForm;