import React from "react";
import { Navigate, Redirect, Route } from "react-router-dom";
import { useTestContext } from "./TestContext";

const TestPrivateRoute = ({ children }) => {
  const { currentTest } = useTestContext();

  
    return currentTest ? 
    children 
   : 
      <Navigate to={{
          pathname: "/tests",
        }} /> 
};

export default TestPrivateRoute;
