import React, { useState } from 'react';
import { Row,Container,Col,Button } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";

//visible header that contains LOGO and Navigation Icon
import "./Info.css";
import "../../assets/css/custom.css";
import ViewModal from '../../modal/viewModal';
import AddSATUserForm from '../../Component/Forms/addSATUserForm';
// import fastimg from "../../assets/img/undraw_fast_car_p4cu.svg";
// import easytouse from "../../assets/img/undraw_Usability_testing_re_uu1g.svg";
// import secure from "../../assets/img/undraw_Security_on_re_e491.svg";
// import feature1 from "../../assets/img/feature-1.png";
// import feature2 from "../../assets/img/feature-2.png";
// import feature3 from "../../assets/img/feature-2.png";


const SatBanner=()=> {
	const [show1,setShow1] = useState(false);
	const [data,setData] = useState('');

    return(
		<section className="sessions pricing py-5 bg-info" id='SAT'>

		<div className="sessions pricing py-5  secPadding" id='SAT' >
		<div className="container">
			<div className="row">
				<div className="mx-auto  text-white">
					<h1 className="text-center text-white">SAT Prep Class</h1>
<Row>					
<Col md={7} className="mx-auto text-white" >

					<p className="text- h5">
					The SAT is a standardized test widely used for college admissions in the United States.
					</p>
					<p className="text- h5">
					Nexgen Hub helps make college dreams become a reality with our intensive SAT prep class. Join our SAT prep class now and let us strategically help you ace the SAT in style.
					We also offer assistance, guidance, counselling and advisory services throughout the entire college application process. 
					</p>
					<p className="text- h5">
					Currently,  our SAT prep sessions span a period of <span className='fw-bold text-warning'>10 weeks</span>. 
					
					</p>
					<p className="text- h5">
					We have both weekday and weekend sessions to suit your preferred schedule. 
					</p>
					<p className="text- h5">
					
					Below are our SAT related services and their associated fees:
						</p>
						<br></br>
					
</Col>
<Col md={4} lg={4} className="mx-auto text-black" >

					<div className="Row text-primary">

					<div className='card mb-5 mb-lg-0' >
					<div className="card-body">
						{/* <h6 className="card-title  text-capitalize text-center">Prep Registration Fee (Non-refundable) </h6> */}
						{/* <h5 className="card-title text-primary fw-bolder text-uppercase text-center">Enroll For a 23.5% Discount</h5>
						<h6 className="card-title text-center text-decoration-line-through text-danger"><span className="period">GH₵850</span></h6>    */}
						{/* <h6 className="card-price text-primary text-center ">{"GH₵2000"} <span className="period">only</span></h6>    */}
					
							
						{/* <hr/> */}
						<h5 className="card-title  text-center text-capitalize ">Prep Class Fee </h5>
						<h6 className="card-price text-primary text-center ">{"GH₵2000"} <span className="period">only</span></h6>   
						<hr/>
						<h5 className="card-title  text-center text-capitalize ">SAT Test Fee </h5>
						<h6 className="card-price text-primary text-center ">{"$120"} <span className="period">only</span></h6>   
						<hr/>
						 <h5 className="card-title  text-center text-capitalize">College Application Processing (Optional)</h5>
						<h6 className="card-price text-primary text-center ">{"GH₵5000"} <span className="period">only</span></h6>   
						<ul className="fa-ul">
								
						</ul>
						<div className="d-grid">
						<Button  onClick={()=>setShow1(true)} className="btn btn-primary text-uppercase fw-bolder">Enroll Now</Button>
						
						
						</div>


					</div>
        </div>
        
      </div>
</Col>
</Row>

	  <p className='text-center'>
					{/* <a className="btn btn-light text-primary fw-bolder text-uppercase mx-auto" onClick={()=>setShow1(true)} > Register Now!</a> */}
              {/* <Button target='_blank' href='https://forms.gle/BpdtggrPkjkp9eGn6' className="btn btn-light text-primary fw-bolder mx-auto text-uppercase">Enroll Now</Button> */}

					</p>
					<div className="d-grid">
              
              <ViewModal
												handleClose={() => setShow1(false)}
												show={show1}
												button="close"
												title={data.session}>
												<div className="row">	
												<AddSATUserForm type={data} />
											</div>
											</ViewModal>
            </div>
					</div>
			</div>
		</div>
	</div>
</section>

    );
}
export default SatBanner;











