import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Badge} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
// import { useAuth } from '../Context/AuthContext';
import logo from '../../assets/img/logo.png'
import ViewModal from '../../modal/viewModal';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight } from '../../views/Admin/Dashboard/DashboardElement';
import ViewUser from '../view/ViewUsers';

const TableRow =({studentData,reload})=> {
    const [show, setShow] = useState(false);
    const closeModal = () => {
      setShow(false);
    };
    return (
        <tr>
        <td className="text-center text-muted">#{studentData.session}</td>
        <td>
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3">
                        <div className="widget-content-left">
                            <img width="40" className="rounded-circle" src={studentData.img||logo} alt="Avatar"/>
                        </div>
                    </div>
                    <div className="widget-content-left flex2">
                        <div className="widget-heading">{studentData.firstname} {studentData.othername} {studentData.lastname}</div>
                    </div>
                </div>
            </div>
        </td>
        <td className="text-center">
            {studentData.pd ?  studentData.pd :"N/A"}
            </td>
        <td className="text-center">
            {studentData.pemail}
            </td>
        <td className="text-center">
            <Badge bg="warning" text="dark" className="bg-warning">{studentData.mediaconsent ? 'Yes':'No'}</Badge>
        </td>
        <td className="text-center">
            <Badge bg={studentData.modeOfLesson ? 'success' : 'light'} text="dark" className="bg-info">{studentData.modeOfLesson ? studentData.modeOfLesson : 'not stated'}</Badge>
        </td>
        <td className="text-center">
        <ViewModal
          show={show}
          handleClose={closeModal}
          title="Payment Details"
        >
          <ViewUser data={studentData} reload={reload}/>
        </ViewModal>
        <Button
          onClick={() => setShow(true)}
          type="button"
          className="btn btn-success btn-sm"
        >
          Details
        </Button>
      </td>
    </tr>
    );
  }

  const TableBody =({studentData,reload})=>{
     
    return(
        <tbody>
           {studentData.map ( (element, i ) => {
             return <TableRow 
                        studentData={element}
                        reload ={reload}
                         key = {i}
                        />
              })}
           
             
           </tbody>
         );
}


const StudentList =({students,title,reload})=>{
    const [studentData,setStudentData]= useState(students);

    return(
                    
            //  {/* table for recent students */}
             <Row className="bg-white p-2 m-auto mx-auto mt-2">
                 <div className="col-md-12">
                    <Card className="main-card mb-3 card">
                        <Card.Header className="bg-white">
                            <Row>
                            <Col sm={12} md={4}>{title}</Col>
                            {/* <ActionButtonRight className="col-sm-12 col-md-3 text-end ">
                                <div role="group" className="btn-group-sm btn-group">
                                    <Button active className=" btn btn-info">pending </Button>
                                    <Button className="btn btn-info">running</Button>
                                    <Button className="btn btn-info">Ended</Button>
                                </div>
                            </ActionButtonRight> */}
                            </Row>
                            
                        </Card.Header>
                        { !studentData &&
                                       <Alert variant="warning" className="mx-auto my-2 text-center"> <strong>No students Yet</strong> </Alert>
                               
                                }
                        { studentData && 
                                
                                <div className="table-responsive">
                            <Table className="align-middle mb-0 table table-borderless table-striped table-hover text-center">
                                <thead>
                                    <tr>
                                        <th className="text-center">Session</th>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">PD</th>
                                        <th className="text-center">Parents Email</th>
                                        <th className="text-center">Media consent</th>
                                        <th>Mode Of class</th>
                                        <th className="text-center">Date of Birth</th>
                                    </tr>
                                </thead>
                                
                                
                                { studentData && <TableBody studentData={students} reload={reload}/>}
                            </Table>
                        </div>
                        }
                    </Card>
                </div>
            </Row>

    )
}


export default StudentList;