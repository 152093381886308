import React ,{ useState ,useEffect} from "react";
import {db,timestamp} from '../../firebase';
import { addDoc, arrayUnion, setDoc, doc, getDoc, increment, runTransaction,updateDoc,Timestamp } from "@firebase/firestore";
import Hashids from 'hashids'
import { stringify } from "@firebase/util";


async function MakeBranchLive(branch){
    let error = ''
    let success='';
    
    
    
        //references
        
        // Create a reference to the SF doc.
    
        var branchRef = doc(db,"branches",branch)
       

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(branchRef);

                if (!sfDoc.exists()) {
                  throw "Branch does not exist!";
                }

               
            
                transaction.update(branchRef, { 
                    closed:false,
                 });
               
               
                

              });
              // console.log(timestamp.fromDate(data.date))
              console.log("Branch Updated successfully!");
              success = true;
            
            } catch (e) {
              console.log("Branch Update failed: ", e);
              error = "Branch Update failed! ";
             
              
            }

       
    return {error,success}

}

export default MakeBranchLive;