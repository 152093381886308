import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link,useHistory, useParams } from 'react-router-dom';
// import { useAuth } from '../../../components/Context/AuthContext';
// import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/PartnerDashboardElement';
import logo from "../../../assets/img/logo.png"
import ClassList from '../../../Component/Table/Table';
import { useAuth } from '../../../Component/Context/AuthContext';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import StudentList from '../../../Component/Table/StudentsTable';
import CloseClass from '../../../Component/Hooks/useCloseClass';
import MakeClassLive from '../../../Component/Hooks/useMakeClassLive';
import ViewModal from '../../../modal/viewModal';
import AddUserFormAdmin from '../../../Component/Forms/addUserFormAdmin';
import PartnerStudentList from '../../../Component/Table/PartnerStudentsTable';


const PartnerClasses =()=>{
    let params = useParams();
    const classId = params.classId;
    const locationId = params.locationId;
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID ,currentUser,userProfile} = useAuth()
    const [show,setShow] = useState(false);
    const [show1,setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    const [sect, setSect] = useState()
    const [ classData,setClassData] = useState()
    const [ studentData,setStudentData] = useState()
    const [ activeId,setActiveId] = useState()
    const [data, setData] = useState()
    const [vdata, setVData] = useState()
    const [sdata, setSData] = useState()
    const [jdata, setJData] = useState()
    const [location, setLocation] = useState()
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

  const fetchClassData =async()=>{
    setLoading(true)

    let seclassData = {}
    const q = doc(db, "classes",classId);
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
    
        const docSnap = await getDoc(q);

        if (docSnap.exists()){
        console.log("Document data:", docSnap.data());
        seclassData = docSnap.data();
        setClassData(seclassData);

        } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setError('Class Not Found!')
        }
    
    // splitsession(classesdata);
    setLoading(false)

    }
  const GoLive = async()=>{
    // console.log(userID);
    setLoading(true)

    const {error,success} = await MakeClassLive(userID,classData);

    setError(error);
    setSuccess(success);
    setLoading(false)
    
    fetchClassData();

}
  const GoOffline = async()=>{
    // console.log(userID);
    setLoading(true)

    const {error,success} = await CloseClass(userID,classData);

    setError(error);
    setSuccess(success);
    setLoading(false)
    
    fetchClassData();

}

  useEffect(()=>{
    const fetchLocationData =async()=>{
      setLoading(true)

      let locationData = {}
     const q = doc(db, "branches",locationId);
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
    
      const docSnap = await getDoc(q);

      if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      locationData = docSnap.data();
      } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setError('Branch Not Found!')
      }
    
    await setLocation(locationData);
    fetchClassData();
    setLoading(false)
    }

    const fetchClassData =async()=>{
        setLoading(true)

        let seclassData = {}
        const q = doc(db, "classes",classId);
    //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
        
            const docSnap = await getDoc(q);

            if (docSnap.exists()){
            console.log("Document data:", docSnap.data());
            seclassData = docSnap.data();
            setClassData(seclassData);

            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setError('Class Not Found!')
            }
        
        // splitsession(classesdata);
        setLoading(false)
        fetchstudentsData(seclassData.class)

        }

             const fetchstudentsData =async(classData)=>{
                 console.log("--->",classData);
                     setLoading(true)
                     let q = ''
                     let studentsdata = []
                     if(classData){
                        if(classData.length > 0){
                        q = query(collection(db, "students"), where("id","in",classData));
                        }else{
                        q = query(collection(db, "students"), where("id","==", classData ));
                        }

                    //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
                        const  querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            console.log(doc.id, "new => ", doc.data());
                            studentsdata.push(doc.data())
                        });
                        }
                    setSect(studentsdata);
                    //  splitsession(classesdata);
                    setLoading(false)

                   }
      
          
            //   fetchstudentsData()
            fetchLocationData();

      
        },[])
      

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/partners/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/partners/dashboard/"+locationId }} >
                {location && location.name}
                </Breadcrumb.Item>                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"#" }} active >
                {classData && classData.session} -{classData && classData.date} 
                </Breadcrumb.Item>
                
            </Breadcrumb>
            <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={ logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={6}>
                        <h2 className='text-capitalize'>{classData && classData.session}</h2>
                        <span>{classData && classData.date} | {classData && classData.start } - {classData && classData.session}</span>
                        <h5 className='text-capitalize'>{classData && classData.class.length} Enrolls</h5>
                    </Col>
  
             </HeaderBanner>
             <div className='mt-2'>
              {(!loading && !error && sect) && <PartnerStudentList students={sect}/>}
             {loading && <Spinner animation="border" variant="info"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}
             </div>
             

        </Container>
    

    </Body>
    )
}

export default PartnerClasses;