import React ,{ useState ,useEffect} from "react";
import {db, firestore,timestamp} from '../../firebase';
import { addDoc, arrayUnion,arrayRemove, collection, doc, getDoc, increment, runTransaction,updateDoc } from "@firebase/firestore";
import Hashids from 'hashids'


async function DeleteUser(userID,classID){
    let error = ''
    let success='';
    
    
    
        //references
        const createdAt = timestamp;
        
        // Create a reference to the SF doc.
        var classRef = doc(db,"classes",classID)
        var logRef = doc(db,"log",'all')
        var countRef = doc(db,"students",userID)
       

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(classRef);
                const studentDoc = await transaction.get(countRef);

                if (!sfDoc.exists()) {
                  throw "Class does not exist!";
                }

                if ((sfDoc.data().class.length) >= 15) {
                  throw "Class is full!";
                }
               
            
                transaction.update(classRef, { 
                    count:increment(-1),
                   class: arrayRemove(userID)
                 });
               
                transaction.update(countRef, { 
                    deleted: true,
                 });
               
                // transaction.delete(countRef);
              });
              console.log("Student Registered successfully!");
              success = true;
              updateDoc(logRef, {
                createdAt,
                log: "Student deleted successfully!",
          
            });
            } catch (e) {
              console.log("Registration failed: ", e);
              error = "Deletion failed! ";
             
              updateDoc(logRef, {
                createdAt,
                log: "Deletion failed",
                

            });
            }

       
    return {error,success}

}

export default DeleteUser;