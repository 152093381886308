import { initializeApp } from 'firebase/app';
import { getFirestore,serverTimestamp } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  //...
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const fbapp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fbapp);
const functions = getFunctions(fbapp);
const db = getFirestore();
const storage = getStorage();

const auth = getAuth();
const timestamp = serverTimestamp()

export { auth,db, fbapp,functions,timestamp, storage as default };
