import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb, Badge} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"
import ClassList from '../../../Component/Table/Table';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../Component/Context/AuthContext';
import AddBranchForm from '../../../Component/Forms/addBranchForm';
import ViewModal from '../../../modal/viewModal';


const Branches =()=>{
    const [electionData,setElectionData] = useState([])
    let params = useParams();
    const locationId = params.locationId;
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID ,currentUser,userProfile} = useAuth()
    const [show,setShow] = useState(false);
    const [show1,setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    const [sect, setSect] = useState()
    const [ location,setLocation] = useState()
    const [ activeId,setActiveId] = useState()
    const [data, setData] = useState()
    const [vdata, setVData] = useState()
    const [sdata, setSData] = useState()
    const [jdata, setJData] = useState()
 
    const closeModal =()=>{
      setShow(false)
      // FetchProduct()
    }

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

 const fetchLocationClassesData =async(locationId)=>{
   setActiveId(locationId)
        setLoading(true)

        let classesdata = []
       const q = query(collection(db, "classes"), where("closed", "==", false),where('locationId',"==",locationId));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      splitsession(classesdata);
      setLoading(false)
      }

      const fetchclosedClassesData =async(locationId)=>{
        setActiveId(locationId)
             setLoading(true)
     
             let classesdata = []
            const q = query(collection(db, "classes"), where("closed", "==", true), where("locationId","==",locationId));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             classesdata.push(doc.data())
           });
           
           setSect(classesdata);
           splitsession(classesdata);
           setLoading(false)
           }
 


    useEffect(()=>{
        const fetchAllClassesData =async(locationId)=>{
            setActiveId(locationId)
                 setLoading(true)
         
                 let classesdata = []
       const q = query(collection(db, "classes"));
         //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
               const  querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                 // doc.data() is never undefined for query doc snapshots
                 console.log(doc.id, " => ", doc.data());
                 classesdata.push(doc.data())
               });
               
               setSect(classesdata);
               splitsession(classesdata);
               setLoading(false)
               }
    
         const fetchLocationData =async()=>{
            setLoading(true)
    
            let locationData = []
           const q = query(collection(db, "branches"));
    //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
          const  querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            locationData.push(doc.data())
          });
          
          setLocation(locationData);
          // splitsession(classesdata);
          setLoading(false)
          }
            fetchLocationData();
    
      },[])
    
    

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/myelections" }} active >
                    Branches 
                </Breadcrumb.Item>
                
            </Breadcrumb>
            <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <Button className="addbutton my-auto btn my-auto mx-auto" onClick={() => setShow(true)}>
                           <BsPlus/>  New branch
                            </Button>
                            <ViewModal
                            title="Add Branch"
                            handleClose={closeModal}
                            show={show}
                            button="Add Branch"
                            >
                            <AddBranchForm closeModal={closeModal} />
                            </ViewModal>
                    </Col>
            
             <Row>
             <div>
                    {
                location && location.map ( (element, i ) => {
                    return <Link to={"/dashboard/branches/"+element.id}
                    // onClick={()=>fetchLocationClassesData(element)}
                    className = 'branch-btn text-primary fw-bolder text-uppercase m-2'>{element.location} 
                    {element.closed ? <Badge bg="secondary">Offline</Badge> : <Badge bg="info" >Live</Badge>}
                    </Link>
                    })
                    }
            </div>
             </Row>
             {/* {(!loading && !error) && <ClassList classes={electionData}/>} */}
             {loading && <Spinner animation="grow" variant="success"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}

        </Container>
    

    </Body>
    )
}

export default Branches;