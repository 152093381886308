import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';

// import { useAuth } from '../../Context/AuthContext';
// material ui imports

 import style from 'bootstrap/dist/css/bootstrap.min.css';
 import {PaystackButton} from "react-paystack";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from '@firebase/storage';
import AddUser from '../Hooks/useAddUser';
import { FaLastfmSquare } from 'react-icons/fa';
// import { DropzoneArea } from 'material-ui-dropzone';
import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import IonRangeSlider from 'react-ion-slider'
import { Slider } from '@material-ui/core';
import { nextSaturday,format, parse, addWeeks, parseISO, isDate, isSaturday } from 'date-fns';
import AddHubUser from '../Hooks/useAddHubUser';
import PaymentHub from '../Helpers/PaymentHub';
//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const AddHubUserFormAdmin =({closeModal,minage,type,location})=>{
     // files to upload
  var price = 240;
  const [data, setData] = useState({duration:1,date:'',session:type,location:location.id,class:type.id});
  const [amount, setAmount] = useState(240);
  const [mainError, setMainError] = useState('');
  const [img, setImg] = useState();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success, setSuccess] = useState(false);
  const [paid, setPaid] = useState(false);
  const [checked,setChecked] = useState(false)
  const [avclasses,setAvclasses] = useState()
  

  // FUNCTIONS FOR SLIDE 
  
  function valuetext(value) {
    return `${value} months`;
  }

  const marks = [
    {
      value: 1,
      label: '1 Month',
    },
    
    {
      value: 3,
      label: '3',
    },
    {
      value: 5,
      label: '5 Months',
    },
    
    {
      value: 10,
      label: '10 ',
    },
    
    {
      value: 12,
      label: '12 Months',
    },
  ];

  const handleChange = (event, newValue) => {
    let val = newValue;
    let nam = 'duration';

    UpdateData(nam, val);
    setAmount(price*val)
  };
  
  // SLIDE FUNCTIONS END HERE
// DATE FUNCTIONS STARTS HERE
      let weeks = (data.duration * 4)-1;
      let thisday = data.startdate ?  parseISO(data.startdate): new Date();
      // const today = thisday.getDate();format(new Date(data.date),"dd-MM-yyyy")
      const todaystring = thisday.toLocaleDateString("en-US");
      const closestSaturday= isSaturday(thisday) ? thisday: nextSaturday(thisday)
      const lastSaturday= addWeeks(closestSaturday,weeks)
  //
  
  const UpdateData = (item, value) => {
    setData((data) => ({ ...data, [item]: value }));
    setFormError((formError) => ({ ...formError, [item]: "" }));
   
  };
  const fetchData =async(location)=>{
    console.log('in fetch data '+location)
         setLoading(true)
 
         let classesdata = []
        const q = query(collection(db, "hub"),where("closed", "==", false),where("locationId","==",location));
 //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
       const  querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
         // doc.data() is never undefined for query doc snapshots
         console.log(doc.id, " => ", doc.data());
         classesdata.push(doc.data())
       });
       
       UpdateData("locationId",location)
       setAvclasses(classesdata)
       setLoading(false)
       }
  const checkEventHandler = (event) => {
    let nam = event.target.name;

     setChecked(!checked)
     let val = !checked
    UpdateData(nam, val);
  };
  const eventHandler = (event) => {
    let val = event.target.value;
    let nam = event.target.name;
    UpdateData(nam, val);
  };
  const locationEventHandler = (event) => {
    let val = event.target.value;
    let nam = event.target.name;
    console.log(val);
     UpdateData(nam, val);
     fetchData(val);
  };

  
  function getAge(birthYear){
    var currentDate = new Date();
    var dob = new Date(birthYear).getFullYear();
      var currentYear = currentDate.getFullYear();
     var age = currentYear - dob;
      console.log(age);
      return age;

  }
  getAge(data.dob);

  const findFormErrors = () => {
    const newErrors = {};
    // name errors
    if (!data.firstname || data.firstname === "") newErrors.firstname = "Cannot be blank!";
    else if (data.firstname.length > 100) newErrors.firstname = "Name is too long!";
    // name errors
    if (!data.lastname || data.lastname === "") newErrors.lastname = "Cannot be blank!";
    else if (data.lastname.length > 100) newErrors.lastname = "Name is too long!";
    // name errors
    if (!data.pname || data.pname === "") newErrors.pname = "Cannot be blank!";
    else if (data.pname.length > 100) newErrors.pname = "Name is too long!";
    // name errors
    if (!data.dob || data.dob === "") newErrors.dob = "Cannot be blank!";
    else if (getAge(data.dob) < minage) newErrors.dob = "Year not eligible!";
    // name errors
    if (!data.pemail || data.pemail === "") newErrors.pemail = "Add a valid email!";
    else if (data.pemail.length > 100) newErrors.pemail = "Name is too long!";
    // food errors
    if ( !data.pcontact || data.pcontact === '' ) newErrors.pcontact = 'Add a valid Contact!';
    if ( !data.paddress || data.paddress === '' ) newErrors.paddress = 'Add a valid address!';
    if ( !data.school || data.school === '' ) newErrors.school = 'Add your wards school!';

    return newErrors;
  };

  const Submit = async (reference) => {
    // Add students
    setLoading(true)
   
    setPaid(true);
    setLoading(false)
    const { error, success } = await AddHubUser(data,reference,closestSaturday,lastSaturday);

    setMainError(error);
    setSuccess(success);
    // setData({ name: "", email: "", session:type });
    setLoading(false);
    // closeModal()
  };

  const Proceed = async () => {
    // event.preventDefault();
    setLoading(true);

    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormError(newErrors);
      setLoading(false);
      setValidated(false)

    } else {
      // No errors! Put any logic here for the form submission!
      setLoading(true);
      setValidated(true)
    }
  };


useEffect(()=>{
 
},[])

  
  




    // PAYSTACK INTEGRATION

  // SUCCESSFULLY PAID
  // const handlePaystackSuccessAction = async(reference) => {
  //   setLoading(true)
  //   setError(error);
  //   setSuccess(success);
  //   setPaid(true);
  //   setLoading(false)
  //   Submit(reference)
  //   console.log(reference);

  // };

  // const handlePaystackCloseAction = () => {
  //   // implementation for  whatever you want to do when the Paystack dialog closed.
  //   console.log("closed");
    
    

  // };


  // const config = {
  //   reference: (new Date()).getTime().toString(),
  //   currency: "GHS",
  //   email: data.pemail,
  //   amount: 100*price*data.duration,
  //   publicKey: "pk_live_2ecef1c81a94e50860200c001677efee909e0a96",
  //   // publicKey: "pk_test_c926b8bee36c5a4ebd43aa6b68003106cfcee1b8",

  // };

  // const componentProps = {
  //   ...config,
  //   text: "Pay GH₵"+amount,
  //   onSuccess: (reference) => handlePaystackSuccessAction(reference),
  //   onClose: () => handlePaystackCloseAction(),

  // };

  const output = (
    <div className={style}>
    <Form noValidate validated={validated} onSubmit={Proceed} inline>
        {/* <h3>Class Information</h3>
        <hr/> */}
    {/* <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Choose Prefered Branch 
          </Form.Label>
          
          <InputGroup className="form-input col">
            <Form.Select aria-label="Default select example"
             className=""
             type="text"
             name="location"
             required={true}
              value={data.locationId}
             onChange={locationEventHandler}
             isInvalid={!!formError.location}>
          <option value=''>-- Select preferred branch --</option>
         {  location && location.map ( (element, i ) => {
          //  console.log(element);
             return   <option value={element.id}>{element.location} ( {element.name} )</option>
              })
            }
          </Form.Select>

            <Form.Control.Feedback type="invalid">
              {formError.session}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
    <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Choose Class Session 
          </Form.Label>
          
          <InputGroup className="form-input col">
            <Form.Select aria-label="Default select example"
             className=""
             type="text"
             name="class"
             disabled = {avclasses ? false:true}
             required={true}
             value={data.class}
             onChange={eventHandler}
             isInvalid={!!formError.class}>
          <option value="">-- Select Class Session --</option>
         {  avclasses && avclasses.map ( (element, i ) => {
             return   <option value={element.id}>{element.session} ( {element.date} - {element.start}{element.session == "morning" ? 'am' : 'pm' })</option>
              })
            }
          </Form.Select>
          
          </InputGroup>
        </Form.Group>
        <hr/> */}
      <h3>Student Information</h3>
        <hr/>
        {/*user details */}
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            First Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="firstname"
              required={true}
              disabled = {data.class ? false:true}
              value={data.firstname}
              onChange={eventHandler}
              isInvalid={!!formError.firstname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.firstname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Last Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="lastname"
              required={true}
              disabled = {data.class ? false:true}
              value={data.lastname}
              onChange={eventHandler}
              isInvalid={!!formError.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.lastname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3 align-bottom text-md-end mx-auto my-auto">
            Other Names
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="othername"
              required={true}
              disabled = {data.class ? false:true}
              value={data.othername}
              onChange={eventHandler}
              isInvalid={!!formError.othername}
            />
            <Form.Control.Feedback type="invalid">
              {formError.othername}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3 align-bottom text-md-end mx-auto my-auto">
            Date of Birth
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="date"
              name="dob"
              disabled = {data.class ? false:true}
              required={true}
              value={data.dob}
              onChange={eventHandler}
              isInvalid={!!formError.dob}
            />
            <Form.Control.Feedback type="invalid">
              {formError.dob}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            E-mail
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="email"
              name="email"
              disabled = {data.class ? false:true}
              required={true}
              value={data.email}
              onChange={eventHandler}
              isInvalid={!!formError.email}
            />
            <Form.Control.Feedback type="invalid">
              {formError.email}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Contact
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="contact"
              required={true}
              disabled = {data.class ? false:true}
              value={data.contact}
              onChange={eventHandler}
              isInvalid={!!formError.contact}
            />
            <Form.Control.Feedback type="invalid">
              {formError.contact}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            School
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="school"
              required={true}
              value={data.school}
              disabled = {data.class ? false:true}
              onChange={eventHandler}
              isInvalid={!!formError.school}
            />
            <Form.Control.Feedback type="invalid">
              {formError.school}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        
     
        <hr/>
        <h3>Guardian Information</h3>
        <hr/>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
          Parents Full Name
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="pname"
              required={true}
              value={data.pname}
              onChange={eventHandler}
              isInvalid={!!formError.pname}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pname}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        
  
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Parents Email
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="email"
              name="pemail"
              required={true}
              value={data.pemail}
              onChange={eventHandler}
              isInvalid={!!formError.pemail}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pemail}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Parents Contact
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="pcontact"
              required={true}
              value={data.pcontact}
              onChange={eventHandler}
              isInvalid={!!formError.pcontact}
            />
            <Form.Control.Feedback type="invalid">
              {formError.pcontact}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Occupation
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="poccupation"
              required={true}
              value={data.poccupation}
              onChange={eventHandler}
              isInvalid={!!formError.poccupation}
            />
            <Form.Control.Feedback type="invalid">
              {formError.poccupation}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
            Address
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="text"
              name="paddress"
              required={true}
              value={data.paddress}
              onChange={eventHandler}
              isInvalid={!!formError.paddress}
            />
            <Form.Control.Feedback type="invalid">
              {formError.paddress}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
    <Form.Group className="row" controlId="validationCustom01 formBasicCheckbox">
    <Form.Label className="col-sm-12 col-md-3  align-bottom text-md-end mx-auto my-auto">
           
          </Form.Label>
          <InputGroup className="form-input col">

            <Form.Check 
            type="checkbox" 
            label="I will like my ward to be featured in promotional videos and photos of the Nexbot program" 
            name='mediaconsent'
            checked = {checked}
            onChange={checkEventHandler}
            />
            </InputGroup>
          </Form.Group>


       
        
        
        <Col className="text-center">
                    <Button
                    onClick={()=>Proceed()}
                    className=" btn-primary text-center"
                    >
                    Proceed {(loading && !error)   &&  <Spinner animation="border" variant="white"/> }
                    </Button>

                </Col>
                </Form>

    </div>
    )

    return(
        <>
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {success && <Alert variant="success">
            <h3 className="mx-auto">{"Registration Succesfuly"}</h3>
              <span className="mx-auto">Kindly check your mail "{data.pemail}" for confirmation and reciepts, Thank you! </span>
            </Alert> }
            {(!error && !success && !validated && !loading)   &&  output }
            {
            (paid && !success )   && 
            <div className="mx-auto text-center" > 
            {/* loading gif */}
              <Spinner className="mx-auto" animation="border" variant="info"/>
            </div>}
            {
            (loading )   && 
            <div className="mx-auto text-center" > 
            {/* loading gif */}
              <Spinner className="mx-auto" animation="border" variant="info"/>
            </div>}
            {(!error && !success && !paid && validated)   &&  <Col className="text-center">
            {/* {(!error && !success && !validated)   &&  <Col className="text-center"> */}
              <div className='text-end fst-italic text-danger'>price = GH₵ {amount}.00</div>
              <h3>
                You are almost there...
              </h3>
                <Row className='container mx-auto'>
               
                  
             

              {/* start today  =  {format(today,"dd-MM-yyyy")}; */}

<br/>
              {/* start  =  {todaysrting};
              could start  {format(closestSaturday,"dd-MM-yyyy")} <br/>
              ends on {format(lastSaturday,"dd-MM-yyyy")} */}
              <Row>
          <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12  align-bottom fw-bolder text-md-start mx-auto my-auto">
            Choose a starting date.
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="date"
              name="startdate"
              required={true}
              value={format(closestSaturday,"yyyy-MM-dd")}
              // defaultValue={"2021-08-01"}
              onChange={eventHandler}
              isInvalid={!!formError.startdate}
            />
            <Form.Control.Feedback type="invalid">
              {formError.startdate}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="row my-md-3" controlId="validationCustom01">
          <Form.Label className="  align-bottom fw-bolder  text-md-start mx-auto my-auto">
            Select the duration (months)
          </Form.Label>
          
          <InputGroup className="form-input ">
          <Slider 
                  aria-label=""
                  defaultValue={1}
                  size="medium"
                  getAriaValueText={()=>valuetext(data.duration)}
                  step={1}
                  name ={"duration"}
                  min = {1}
                  max = {12}
                  marks ={marks}
                  valueLabelDisplay="on"
                  value={data.duration}
                  onChangeCommitted={handleChange}
                  onChange={handleChange}
                  />
                  
          </InputGroup>
        </Form.Group>

        <Form.Group className="row" controlId="validationCustom01">
          <Form.Label className="col-sm-12 align-bottom text-md-start mx-auto my-auto fw-bolder">
            Expiry Date
          </Form.Label>
          <InputGroup className="form-input col">
            <Form.Control
              className=""
              type="date"
              name="exp"
              readOnly
              required={true}
              value={format(lastSaturday,"yyyy-MM-dd")}
              onChange={eventHandler}
              isInvalid={!!formError.dob}
            />
            <Form.Control.Feedback type="invalid">
              {formError.dob}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        </Row>
        <span className='fst-italic'>NB: Your selected duration is exaclty {weeks} weeks</span>
        <span className='fst-italic text-danger'>Your selected duration can be extended later in your dashboard</span>

        </Row>
              {/* <h6 className="text-warning">Make payment here</h6>
              <PaystackButton disabled={validated}
                  className="paystack-button mx-auto px-3"
                  {...componentProps}
                /> */}
                <h6 className="text-warning">Add student as Admin</h6>
         
         <button className='btn paystack-button mx-auto px-3 text-white' onClick={()=>Submit("Admin Added")}>Add Student</button>
        </Col> }

            
        </>
     
    );
}

export default AddHubUserFormAdmin;