import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';

// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
// import { firestore, storageRef } from '../../../firebase';

import { useAuth } from '../Context/AuthContext';
import AddClass from '../Hooks/useAddClass';
import AddBranch from '../Hooks/useAddBranch';
// import 'bootstrap/dist/css/bootstrap.min.css';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const AddBranchForm =({closeModal})=>{
     // files to upload
  const [data, setData] = useState([]);
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const { userID } = useAuth()


  const UpdateData =(item,value)=>{
    
        setData(data => ({...data, 
            [item]:value}))
    
    setFormError(formError => ({...formError, [item]:''}))
  }
  

    const eventHandler = (event) => {
        let val = event.target.value;
        let nam = event.target.name;
        UpdateData(nam,val)
    };


    const findFormErrors = () => {
        const newErrors = {}
        // name errors
        if ( !data.name || data.name === '' ) newErrors.name = 'Cannot be blank!'
        if ( !data.location || data.location === '' ) newErrors.location = 'Cannot be blank!'
        if ( !data.gps || data.gps === '' ) newErrors.gps = 'Cannot be blank!'
        if ( !data.address || data.address === '' ) newErrors.address = 'Cannot be blank!'
        if ( !data.partnerId || data.partnerId === '' ) newErrors.partnerId = 'confirm the Partner!'
        if ( !data.partnerCode || data.partnerCode === '' ) newErrors.partnerCode = 'choose a partner!'
      
    
        

        return newErrors;
    }

    
    const Submit = async ()=>{ 
        // Add Product
        const {error,success} = await AddBranch(data);
        
        setMainError(error);
        setSuccess(success);
        setData('');
        // closeModal()
        setLoading(false);

   }

     
    
    const Proceed = async () => {
        // event.preventDefault();
    
        const newErrors = findFormErrors()
            // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setFormError(newErrors)
            } else {
            // No errors! Put any logic here for the form submission!
            setLoading(true)
                // uploadImage();
                Submit();
                // continue with other rendering
            }
        
    };

    const output = <>
           <Form noValidate validated={validated} onSubmit={Proceed} inline>

           <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
                    Branch Name
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="name"
                    required ={true}
                    value={data.name}
                    onChange={eventHandler}
                    isInvalid={ !!formError.name }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.name}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group> 

           <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
                    Branch Address (Exact location of branch building)
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="address"
                    required ={true}
                    value={data.address}
                    onChange={eventHandler}
                    isInvalid={ !!formError.address }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.address}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group> 
           
           <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
                    Branch Location (Area/Town of branches - Keep short - e.g East Legon / Tema)
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="location"
                    required ={true}
                    value={data.location}
                    onChange={eventHandler}
                    isInvalid={ !!formError.location }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.location}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group> 

           <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
                    Branch GPS (Google direction)
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="gps"
                    required ={true}
                    value={data.gps}
                    onChange={eventHandler}
                    isInvalid={ !!formError.gps }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.gps}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group> 

           <Form.Group className="row" controlId="validationCustom04">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Branch Partner
                    </Form.Label>
                    <InputGroup className="form-input col" >
                    
                    <Form.Control
                    as="select"
                    className=" col"
                    name="partnerCode"
                    value={data.partnerCode}
                    onChange={eventHandler}
                    isInvalid={ !!formError.partnerCode }
                    
                    >
                    <option value=''>Select session</option>
                    <option value='nexbot'>NEXGEN </option>
                    <option  value='ghla'>GHLA</option>
                   
                    </Form.Control>
                    </InputGroup>
                </Form.Group>

           <Form.Group className="row" controlId="validationCustom04">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Confirm Partner
                    </Form.Label>
                    <InputGroup className="form-input col" >
                    
                    <Form.Control
                    as="select"
                    className=" col"
                    name="partnerId"
                    value={data.partnerId}
                    onChange={eventHandler}
                    isInvalid={ !!formError.partnerId }
                    
                    >
                    <option value=''>Select session</option>
                    <option value='NEXGEN'>NEXGEN </option>
                    <option  value='PTNNXBOTGHLA'>GHLA</option>
                   
                    </Form.Control>
                    </InputGroup>
                </Form.Group>

                

           

       
   

                <Col className="text-center">
                    <Button
                    onClick={()=>Proceed()}
                    className=" btn-success text-center"
                    >
                    Proceed {(loading && !error)   &&  <Spinner animation="border" variant="white"/> }
                    </Button>

                </Col>
                </Form>

    </>

    return(
        <>
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {success && <Alert variant="success"><h3 className="mx-auto">{"Product added Succesfuly"}</h3></Alert> }
            {(!error && !success)   &&  output }

            
        </>
     
    );
}

export default AddBranchForm;