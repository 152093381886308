import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
// import { useAuth } from '../../../Components/Context/AuthContext';
// import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
// import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"
import ClassList from '../../../Component/Table/Table';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../Component/Context/AuthContext';


const UpcomingClasses =({classes,locationId})=>{
    const [electionData,setElectionData] = useState([])
   
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID ,currentUser,userProfile} = useAuth()
    const [show,setShow] = useState(false);
    const [show1,setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    const [sect, setSect] = useState()
    const [ location,setLocation] = useState()
    const [ activeId,setActiveId] = useState()
    const [data, setData] = useState()
    const [vdata, setVData] = useState()
    const [sdata, setSData] = useState()
    const [jdata, setJData] = useState()
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}




    useEffect(()=>{
    //   const fetchClassesData =async()=>{
    //     setActiveId(locationId)
    //          setLoading(true)
     
    //          let classesdata = []
    //         const q = query(collection(db, "classes"), where("closed", "==", false), where("locationId","==",locationId));
    //  //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
    //        const  querySnapshot = await getDocs(q);
    //        querySnapshot.forEach((doc) => {
    //          // doc.data() is never undefined for query doc snapshots
    //          console.log(doc.id, " => ", doc.data());
    //          classesdata.push(doc.data())
    //        });
           
    //        setSect(classesdata);
    //        splitsession(classesdata);
    //        setLoading(false)
    //        }
           setSect(classes);
           splitsession(classes);

        
            // fetchClassesData();
    
      },[classes])
    
    

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <h1>Upcoming Classes</h1>
             <Row>
                 <Col>
             {(!loading && !error && jdata) && <ClassList classes={jdata} title='Junior Courses'/>}   
                 </Col>
                 <Col>
             {(!loading && !error && sdata) && <ClassList classes={sdata} title='Senior Courses'/>}
                 </Col>
             </Row>
             {loading && <Spinner animation="grow" variant="success"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}

        </Container>
    

    </Body>
    )
}

export default UpcomingClasses;