import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { IoMailOutline,IoLogoLinkedin,IoLogoTwitter,IoLogoFacebook,IoLogoInstagram } from "react-icons/io5";
 import "./Footer.css";
 import { FaEnvelope, FaFacebook, FaMapMarker, FaPhone, FaInstagram, FaTwitter } from "react-icons/fa";
 import { FaXTwitter } from "react-icons/fa6";

import banner from '../../assets/img/headingbanner.jpg';
export default function Footer() {
      return (
        <footer id="footer">

			{/* <!-- .footer start -->  */}
			<div className="footer section">
				<div className="container">
					<h1 className="title text-center" id="contact">Contact Us</h1>
					<div className="space"></div>
					<div className="row">
						
						<div className="col-sm-6">
							<div className="footer-content">
								{/* <form role="form" id="footer-form">
									<div className="form-group has-feedback">
										<label className="sr-only" for="name2">Name</label>
										<input type="text" className="form-control" id="name2" placeholder="Name" name="name2" required />
										<i className="fa fa-user form-control-feedback"></i>
									</div>
									<div className="form-group has-feedback">
										<label className="sr-only" for="email2">Email address</label>
										<input type="email" className="form-control" id="email2" placeholder="Enter email" name="email2" required/>
										<i className="fa fa-envelope form-control-feedback"></i>
									</div>
									<div className="form-group has-feedback">
										<label className="sr-only" for="message2">Message</label>
										<textarea className="form-control" rows="8" id="message2" placeholder="Message" name="message2" required></textarea>
										<i className="fa fa-pencil form-control-feedback"></i>
									</div>
									<input type="submit" value="Send" className="btn btn-default"/>
								</form> */}
								<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31764.293242612053!2d-0.1678469514343206!3d5.6351877988140116!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72927f1fd4aab91d!2sOne%20Heart%20Africa%20Academy!5e0!3m2!1sen!2sgh!4v1650913935437!5m2!1sen!2sgh" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
							</div>
						</div>
						
						<div className="col-sm-6">
							<div className="footer-content">
								

							<div className="widget-content">


								<a target='_blank' href="https://g.page/oneheartghana?share">
								<p className="contacts"><FaMapMarker/>One Heart Africa Academy, Suya St, East Legon</p></a>

								<a href='tel:+233-596-029-368'>
								<p className="contacts"> <FaPhone/>  +233 596-029-368</p>
								</a>
								<a href='mailto:nexgeneducationalhub@gmail.com'>
								
								<p className="contacts"><FaEnvelope/> nexgeneducationalhub@gmail.com</p>

								</a>

							</div>

						<aside/>
								<ul className="social-links">
									<li className="facebook"><a target="_blank" href="https://web.facebook.com/nexgenhub/"><FaFacebook/></a></li>
									<li className="intagram"><a target="_blank" href="https://www.instagram.com/nexgeneducationalhub/"><FaInstagram/></a></li>
									<li className="twitter"><a target="_blank" href="https://twitter.com/nexgenhub"><FaXTwitter/></a></li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- .footer end -->

			<!-- .subfooter start -->  */}
			<div className="subfooter">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<img src={banner} alt='banner' className="d-none"/>
							<p className="text-center">Copyright © 2020. <a href="https://nexgeneducationalhub.com" target="_blank">Nexgen Educational Hub</a></p>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- .subfooter end --> */}

		</footer>
        );
}