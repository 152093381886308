import React, { useRef, useState } from "react";
// router dom
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import { useAuth } from "../../../contexts/AuthContext";

// css import
import "./Auth.css";

// boostraP IMPOTS
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useAuth } from "../../../Component/Context/AuthContext";
// import { useAuth } from "../../../../Components/Context/AuthContext";

// image imports
// import Image from "../../../images/img-2.png";
// import { TutorAuthHeader } from "./TutorAuthHeader";


// header import

export default function SignIn() {
  const emailRef = useRef();
  const passwordRef = useRef();
 
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const { login } = useAuth();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/dashboard" } };


  async function handleSubmit(e) {
    e.preventDefault();

   

    try {
      setError("");
      setLoading(true);

        await login(emailRef.current.value, passwordRef.current.value);
        console.log("completed login, going to verify")

      //               await verifyUser();
      history.push(from);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }
  return (
    <Container fluid>
      <Row>
        {" "}

      
        <Col  className="loginsideimg-S">
          <Container fluid className="my-auto my-auto">
            <Row className="height-full-S">
              <Col sx={11} sm={6} md={4} lg={3}  className="mx-auto my-auto text-center container">
               
                <h2 className="header m-4">Welcome Back!</h2>
                <p>Please sign in to continue</p>
               
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
               
                  <Form.Group>
                    <Form.Control
                      className="form-input  m-2"
                      type="email"
                      placeholder="Enter email"
                      ref={emailRef}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      className="form-input  m-2"
                      type="password"
                      placeholder="Password"
                      ref={passwordRef}
                      required
                    />
                  </Form.Group>
                  <Button
                    disabled={loading}
                    variant="success"
                    className="primary-button"
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Form>
                <p className="text-muted p-1 mx-auto d-block">
                Don't have an account yet? <Link to="/signup" className="text-success "> Sign up here.</Link>
                </p>
              </Col>
              
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
