import React ,{ useState ,useEffect} from "react";
import {db, firestore,timestamp} from '../../firebase';
import { addDoc, arrayUnion, collection, doc, getDoc, increment, runTransaction,updateDoc } from "@firebase/firestore";
import Hashids from 'hashids'


async function RenewHubUser(data,reference,startDate,expiryDate){
    const hashids = new Hashids('',5)
    let error = ''
    let success='';
    let code="";
    
    
    
        //references
        const createdAt = timestamp;
        
        // Create a reference to the SF doc.
        var hubRef = doc(db,"hub",data.class)
        var locationRef = doc(db,"branches",data.location.id)
        var logRef = doc(db,"log",'all')
        var countRef = doc(db,"MC","count")
       

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(hubRef);
                const locDoc = await transaction.get(locationRef);
                const countDoc = await transaction.get(countRef);

                if (!sfDoc.exists()) {
                  throw "Class does not exist!";
                }
                if (!locDoc.exists()) {
                  throw "Branch does not exist!";
                }
                
                var newCount = (countDoc.data().hubstudentsRenewals||0) + 1;
                console.log(newCount)
                code=hashids.encode(newCount); 
            
                transaction.update(hubRef, { 
                    renewals:increment(1),
                 });
                transaction.update(countRef, { 
                  hubstudentsRenewals:newCount,
                 });
                transaction.update(doc(db,"Hubstudents",data.id), { 
                   
                    reference,
                    expiryDate,
                    startDate,
                    hub:sfDoc.data(),
                    lastRenewed: createdAt,
                    renewals:arrayUnion(code)

                 });
                transaction.set(doc(db,"hubrenewals",code), { 
                    id:code,
                    firstname : data.firstname,
                    lastname : data.lastname,
                    studentId: data.id,
                    duration: data.duration,
                    pemail: data.pemail,
                    reference,
                    expiryDate,
                    startDate,
                    hub:sfDoc.data(),
                    createdAt,

                 });
              });
              console.log("Innovator membership renewed successfully!");
              success = true;
              updateDoc(logRef, {
                createdAt,
                log: "Innovator membership renewed successfully!",
                ...data

            });
            } catch (e) {
              console.log("Membership renew failed: ", e);
              error = "Membership renew failed! ";
             
             
            }

       
    return {error,success}

}

export default RenewHubUser;