import React from "react";
import { Navigate, Redirect, Route, useLocation } from "react-router-dom";
// import NotVerifiedPage from "../../views/User/NotVerified";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({ children }) => {
  const { currentUser,setuFrom } = useAuth();
  const location = useLocation

  
   
        setuFrom(location.pathname)

        return currentUser ? 
          children 
         : 
            <Navigate to={{
                pathname: "/login",
                state: { from: location.pathname }
              }} />  
      
   

};

export default PrivateRoute;
