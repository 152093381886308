import React ,{ useState ,useEffect,useContext} from "react";

const PartnerContext =  React.createContext();

export function usePartnerContext() {
    return useContext(PartnerContext)
};


export const PartnerContextProvider =({children})=>{
    const [loading, setLoading] = useState(false);
    const [currentPartner,setPartner] = useState();
    




 const setCurrentPartner= async(data)=>{
    setLoading(true);
    setPartner(data);

    setLoading(false)

}



const setNewPartner= (data)=>{
    setPartner(data);

}
  

    const value = {
        currentPartner,setCurrentPartner,
    }
    return (
        <PartnerContext.Provider value={value}>
            {!loading && children}
        </PartnerContext.Provider>
    )
} 