import React from 'react'
import { Spinner } from 'react-bootstrap'
import SessionCard from '../../Component/SessionCard'
import SessionJuniorCard from '../../Component/SessionJuniorCard'
import SessionSeniorCard from '../../Component/SessionSeniorCard'
import SessionVirtualCard from '../../Component/SessionVirtualCard'
import { courses } from '../../coursedata'
import SessionDroneCard from '../../Component/SessionDroneCard'
import SessionVRCard from '../../Component/SessionVRCard'

  const SessionDivs = ({data,loading,sect,type,location}) =>{

let output="";
      
        if (type === "senior") { output =
                            <SessionSeniorCard 
                            courseDetail={courses.roboticsSenior}
                              ukey = {0}
                            
                            /> 
                            }
          if (type === "junior"){ output =
                            <SessionJuniorCard 
                            courseDetail={courses.roboticsJunior}
                              // key = {i}
                              ukey = {1}
                              location ={location}
                            /> 
                            }
          if (type === "virtuals"){ output =
                              <SessionVirtualCard 
                            courseDetail={courses.webdev}
                              ukey = {2}
                              location ={location}
                            /> 
                            }
          if (type === "drone"){ output =
                              <SessionDroneCard 
                            courseDetail={courses.dronePiloting}
                              ukey = {0}
                              location ={location}
                            /> 
                            }
          if (type === "vr"){ output =
                              <SessionVRCard 
                            courseDetail={courses.vr}
                              ukey = {0}
                              location ={location}
                            /> 
                            }
                  


    return (
      <div>
     
          {output}
           

      
        {
            (loading && !sect)   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              <p>Loading Available Sessions</p>
              </div>

        }
      

</div>
      )}

export default  SessionDivs;