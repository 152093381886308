export const programId={
    robotics:"dulxLFK5unfkodw3P5pw",
    coding:"aSsawjF1UaNwPXLo0f8E",
    dronePiloting:"b1k9n0xANe52ewXiatbU",
    vr:"NMfw8EGnx3P2J0TTxByH",
}
export const courses = {
python : {
    price: 2000,
    duration: 16,
    name: "Python Programming",
    minAge: 10,
    programId:programId.coding 

},
dronePiloting : {
    price: 1000,
    duration: 8,
    name: "Drone coding & Piloting",
    minAge: 10,
    maxAGe:16,
    programId:programId.dronePiloting 

},
vr : {
    price: 120,
    duration: 6,
    name: "Virtual Reality Experience",
    minAge: 10,
    maxAGe:16,
    programId:programId.vr 

},
 webdev :{
    price: 2000,
    duration: 16,
    name: "Web Design (HTML + CSS + Javascript)",
    minAge: 9,
    programId:programId.coding 
},
roboticsSenior :{
    price: 1000,
    duration: 8,
    name: "Senior Robotics (9+)",
    minAge: 9,
    programId:programId.robotics 
},
roboticsJunior :{
    price: 900,
    duration: 8,
    name: "Junior Robotics (5+)",
    minAge: 5,
    programId:programId.robotics 
},hub :{
    price: 300,
  
    programId:programId.robotics 
}

}