import React ,{ useState ,useEffect} from "react";
import {db, firestore,timestamp} from '../../firebase';
import { addDoc, arrayUnion, collection, doc, getDoc, increment, runTransaction,updateDoc } from "@firebase/firestore";
import Hashids from 'hashids'


async function AddUser(data,reference){
    const hashids = new Hashids('',5)
    let error = ''
    let success='';
    let code="";
    
    
    
        //references
        const createdAt = timestamp;
        
        // Create a reference to the SF doc.
        var classRef = doc(db,"classes",data.class)
        var locationRef = doc(db,"branches",data.location)
        var logRef = doc(db,"log",'all')
        var countRef = doc(db,"MC","count")
        const courseRef = doc(db,"courses",data.course?.id)


            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(classRef);
                const locDoc = await transaction.get(locationRef);
                const countDoc = await transaction.get(countRef);
                const courseDoc = await transaction.get(courseRef);
                
                if (!courseDoc.exists()) {
                  throw "Course does not exist!";
                }
                if (!sfDoc.exists()) {
                  throw "Class does not exist!";
                }
                
                if (!locDoc.exists()) {
                  throw "Branch does not exist!";
                }
                
                if ((sfDoc.data().class.length) >= 15) {
                  throw "Class is full!";
                }
                var newCount = countDoc.data().studentsNumber + 1;
                console.log(newCount)
                code=hashids.encode(newCount); 
            
                transaction.update(classRef, { 
                    count:increment(1),
                   class: arrayUnion(code)
                 });
                transaction.update(countRef, { 
                  studentsNumber:newCount,
                 });
                transaction.set(doc(db,"students",code), { 
                    ...data,
                    session:sfDoc.data().session,
                    location:sfDoc.data().location,
                    course:courseDoc.data().name,
                    reference,
                    id:code,
                    class:sfDoc.data(),
                    createdAt
                 });
              });
              console.log("Student Registered successfully!");
              success = true;
              updateDoc(logRef, {
                createdAt,
                log: "Student Registered successfully!",
                ...data

            });
            } catch (e) {
              console.log("Registration failed: ", e);
              error = "Registration failed! ";
             
             
            }

       
    return {error,success}

}

export default AddUser;