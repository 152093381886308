import img1 from "../../assets/img/MG9596.jpg";
import img2 from "../../assets/img/MG9426.jpg";
import img3 from "../../assets/img/MG9540.jpg";
import img4 from "../../assets/img/MG9535.jpg";
import img5 from "../../assets/img/MG9526.jpg";
import img6 from "../../assets/img/MG9519.jpg";
import img7 from "../../assets/img/MG9511.jpg";
import img8 from "../../assets/img/MG9501.jpg";
import img9 from "../../assets/img/MG9476.jpg";
import img10 from "../../assets/img/MG9461.jpg";
import img11 from "../../assets/img/MG9575.jpg";
import img12 from "../../assets/img/MG9433.jpg";
import NXBimg1 from "../../assets/img/NXTBOTSHOOTS/nxbimg36.jpg";
import NXBimg2 from "../../assets/img/NXTBOTSHOOTS/nxbimg45.jpg";
import NXBimg3 from "../../assets/img/NXTBOTSHOOTS/nxbimg3.jpg";
import NXBimg4 from "../../assets/img/NXTBOTSHOOTS/nxbimg55.jpg";
import NXBimg5 from "../../assets/img/NXTBOTSHOOTS/nxbimg60.jpg";
import NXBimg6 from "../../assets/img/NXTBOTSHOOTS/nxbimg69.jpg";
import NXBimg7 from "../../assets/img/NXTBOTSHOOTS/nxbimg6.jpg";
import NXBimg8 from "../../assets/img/NXTBOTSHOOTS/nxbimg70.jpg";
import NXBimg9 from "../../assets/img/NXTBOTSHOOTS/nxbimg73.jpg";
import NXBimg10 from "../../assets/img/NXTBOTSHOOTS/nxbimg35.jpg";
import NXBimg11 from "../../assets/img/NXTBOTSHOOTS/nxbimg38.jpg";
import NXBimg12 from "../../assets/img/NXTBOTSHOOTS/nxbimg41.jpg";



export const gallery = [
  {
    img: img1,
    tagline: "NEXBOT PHOTO",
    
    description: "",
    alt: "image",
   
  },
  {
    img:
    img2
    ,
    tagline: "NEXBOT PHOTO",
    
    description: "",
    alt: "image",
    
  },
  
  {
    img:img3,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
 
  },
  {
    img:
     img4,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
  
  },
  {
    img:
      img5,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  {
    img:
      img6,
    tagline: "NEXBOT PHOTO",
    
    description: "",
    alt: "image",
   
  },
  {
    img:img7,
    tagline: "NEXBOT PHOTO",
    
    description: "",
    alt: "image",
    
  },
  
  {
    img: img8,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
 
  },
  {
    img:
      img9,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
  
  },
  {
    img:
      img10,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  {
    img:
      img11,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  {
    img:
      img12,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
];
export const galleryOhaa2 = [
  {
    img: NXBimg1,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg2,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg3,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg4,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg5,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg6,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg7,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg8,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg9,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
  {
    img: NXBimg10,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  {
    img: NXBimg11,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  {
    img: NXBimg12,
    tagline: "NEXBOT PHOTO",
    description: "",
    alt: "image",
   
  },
  
];
 