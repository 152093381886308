import React from "react";
import { Navigate, Redirect, Route } from "react-router-dom";
import { useHubContext } from "./HubContext";

const HubPrivateRoute = ({ children }) => {
  const { currentHub } = useHubContext();

  
    return currentHub ? 
    children 
   : 
      <Navigate to={{
          pathname: "/hub",
        }} /> 
};

export default HubPrivateRoute;
