import React, { useState } from 'react';
import { Row,Container,Col,Carousel } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";

//visible header that contains LOGO and Navigation Icon
import "./Info.css";

import { Link } from 'react-router-dom';
// import "../../assets/css/custom.css";src\assets\img\MG9431.jpgsrc\assets\img\MG9546.jpg
import bgimg1 from "../../assets/img/BG05.jpg";
import bgimg4 from "../../assets/img/MG9431.jpg";
import bgimg6 from "../../assets/img/MG9501.jpg";
import bgimg2 from "../../assets/img/MG9545.jpg";
import bgimg7 from "../../assets/img/MG9541.jpg";
import bgimg3 from "../../assets/img/MG9546.jpg";
import bgimg5 from "../../assets/img/MG9511.jpg";
import SessionVRCard from '../../Component/SessionVRCard';
import VirtualRealitySessions from './VIrtualReality';
// import easytouse from "../../assets/img/undraw_Usability_testing_re_uu1g.svg";
// import secure from "../../assets/img/undraw_Security_on_re_e491.svg";src\assets\img\MG9511.jpg


function Home() {
    return(
            
    <div className="home" id="banner" >
        <Carousel>
            <Carousel.Item>
                <img
                className="d-block w-100 h-60"
                src={bgimg1}
                alt="First slide"
                />
                <Carousel.Caption className="caption fw-bold">
                
                <div className="caption-box">
                <h3>Welcome to <br/>
                    Nexgen Educational Hub</h3>
                <p>
                We equip those who will serve the next generation with the skills to do so.
                </p>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 h-60"
                src={bgimg4}
                alt="Second slide"
                />

                <Carousel.Caption className="caption fw-bold">
                <div className="caption-box">
                    <h3>A Hub of Innovation</h3>
                    <p>
                    The best education is not given to students. It is drawn out of them.
                    </p>
                    <a href='#hub' className='btn btn-primary text-light fw-bolder rounded'>Join the Hub</a>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 h-60"
                src={bgimg3}
                alt="Third slide"
                />

                <Carousel.Caption className="caption fw-bold">
                
                <div className="caption-box">
                    <h3>Relevant Skills for the Future</h3>
                    <p>
                    We secure a place for your ward in the future of a fast-changing world.
                    </p>
                </div>

                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100 h-60"
                src={bgimg7}
                alt="Third slide"
                />

                <Carousel.Caption className="caption fw-bold">
                
                <div className="caption-box">
                    <h3>A Hallmark of Excellence</h3>
                    <p>
                    Our committment to excellence is why we are the number 1 choice.
                    </p>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={bgimg5}
                alt="Fourth slide"
                />

                <Carousel.Caption className="caption fw-bold">

                <div className="caption-box">
                    <h3>
                    Conducive Learning Environment

                    </h3>
                    <p>
                    We believe where you learn is as important as how. 
                    </p>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={bgimg6}
                alt="6th slide"
                />

                <Carousel.Caption className="caption fw-bold">

                <div className="caption-box">
                    <h3>
                    Quality Tuition Guaranteed

                    </h3>
                    <p>
                    Our team of passionate and dedicated instructors are always ready.                    </p>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            
            </Carousel>
       
    </div>


    );
}
export default Home;











