import React ,{ useState ,useEffect,useContext} from "react";

const HubContext =  React.createContext();

export function useHubContext() {
    return useContext(HubContext)
};


export const HubContextProvider =({children})=>{
    const [loading, setLoading] = useState(false);
    const [currentHub,setHub] = useState();
    




 const setCurrentHub= async(data)=>{
    setLoading(true);
    setHub(data);

    setLoading(false)

}



const setNewHub= (data)=>{
    setHub(data);

}
  

    const value = {
        currentHub,setCurrentHub,
    }
    return (
        <HubContext.Provider value={value}>
            {!loading && children}
        </HubContext.Provider>
    )
} 