import React, { useState } from 'react';
import logo from "../../assets/img/logo.png"
import { RiMenu3Line } from "react-icons/ri";
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { FaDoorOpen, FaTachometerAlt } from 'react-icons/fa';
import { useAuth } from '../Context/AuthContext';
import { usePartnerContext } from '../Context/PartnerContext';
import { useHubContext } from '../Context/HubContext';
import './Header.css';
import { useTestContext } from '../Context/TestContext';

function Header() {
    const [tab, setTab] = useState('home');
    const { currentUser, logout } = useAuth();
    const { currentPartner, setCurrentPartner } = usePartnerContext();
    const { currentHub, setCurrentHub } = useHubContext();
    const { currentTest, setCurrentTest } = useTestContext();

    const scrollToSection = (css) => {
        setTab(css);
        scroller.scrollTo(css, {
            duration: 500,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -50,
        });
    };

    const handleLogout = () => {
        if (currentHub) setCurrentHub('');
        if (currentPartner) setCurrentPartner('');
        if (currentTest) setCurrentTest('');
        if (currentUser) logout();
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg="white" sticky="top" variant="success">
            <Container className="py-3">
                <Navbar.Brand as={Link} to="/">
                    <img alt="Nexgen Educational Hub" src={logo} width="80" height="34" className="d-inline-block align-text-top" />
                </Navbar.Brand>

                <Navbar.Toggle className="no-printme">
                    <RiMenu3Line size={30} color={"grey"} />
                </Navbar.Toggle>
                <Navbar.Collapse className="justify-content-end no-printme">
                    <Nav className="text-center" pullRight>
                        <Nav.Link as={Link} activeClassName="active" active={tab === "home"} className="navlink" onClick={() => scrollToSection("home")} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} activeClassName="active" active={tab === "services"} className="navlink" onClick={() => scrollToSection("services")} to="/#about">We Offer</Nav.Link>
                        <Nav.Link as={Link} activeClassName="active" active={tab === "about"} className="navlink" onClick={() => scrollToSection("about")} to="/#about">About Us</Nav.Link>
                        
                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} active={tab === "sessions"} className="navlink">
                            STEAM Programs <span class="badge  bg-warning badge-warning badge-pill ">New</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} className="navlink" to="/#pricing" onClick={() => scrollToSection("sessions")}>Robotics</Dropdown.Item>
                                <Dropdown.Item as={Link} className="navlink" to="/#coding" onClick={() => scrollToSection("coding")}>Python Programming & Web Design</Dropdown.Item>
                                <Dropdown.Item as={Link} className="navlink" to="/#dronepiloting" onClick={() => scrollToSection("droneP")}>Drone Coding & Piloting <span class="badge  bg-info badge-warning badge-pill ">New</span></Dropdown.Item>
                                <Dropdown.Item as={Link} className="navlink" to="/#virtualReality" onClick={() => scrollToSection("virtualReality")}>Virtual Reality Experiences <span class="badge  bg-info badge-warning badge-pill ">New</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} active={tab === "prep-tests"} className="navlink">
                                Test Prep <span class="badge  bg-danger badge-danger badge-pill "> New</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} className="navlink" to="/#SAT" onClick={() => scrollToSection("SAT")}>SAT</Dropdown.Item>
                                <Dropdown.Item as={Link} className="navlink" to="/#IELTS" onClick={() => scrollToSection("IELTS")}>IELTS <span class="badge  bg-danger badge-danger badge-pill "> New</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Nav.Link as={Link} activeClassName="active" active={tab === "hub"} className="navlink" onClick={() => scrollToSection("hub")} to="/#hub">Join the Hub</Nav.Link>
                        {/* <Nav.Link as={Link} activeClassName="active" active={tab === "SAT"} className="navlink text-warning" onClick={() => scrollToSection("SAT")} to="/#SAT">SAT</Nav.Link> */}
                        <Nav.Link as={Link} activeClassName="active" active={tab === "gallery"} className="navlink" onClick={() => scrollToSection("gallery")} to="/#gallery">Gallery</Nav.Link>
                        <Nav.Link as={Link} activeClassName="active" active={tab === "footer"} className="navlink" onClick={() => scrollToSection("footer")} to="/#contacts">Contact Us</Nav.Link>

                        {(currentUser ) &&
                            <Nav.Link as={Link} activeClassName="active" active={tab === "dashboard"} onClick={() => scrollToSection("dashboard")} className="navlink"  to="/dashboard">
                            <FaTachometerAlt /> Admin Dashboard
                            </Nav.Link>

                        }
                        {(currentPartner ) &&
                            <Nav.Link as={Link} activeClassName="active" active={tab === "dashboard"} onClick={() => scrollToSection("dashboard")} className="navlink"  to="/partners/dashboard">
                            <FaTachometerAlt />Partner Dashboard
                            </Nav.Link>

                        }
                        {(currentTest ) &&
                            <Nav.Link as={Link} activeClassName="active" active={tab === "dashboard"} onClick={() => scrollToSection("dashboard")} className="navlink"  to="/tests/dashboard">
                            <FaTachometerAlt />Staff Dashboard
                            </Nav.Link>

                        }
                        {(currentUser || currentHub || currentPartner || currentTest) ?
                            <span className="navbar-text navlink">
                                <a onClick={handleLogout}>
                                    <FaDoorOpen /> Logout
                                </a>
                            </span>:
                            <Nav.Link as={Link} activeClassName="active" active={tab === "login"} onClick={() => scrollToSection("login")} className="navlink"  to="/login">
                                Login
                            </Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
