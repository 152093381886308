import React ,{ useState ,useEffect} from "react";
import {db,timestamp} from '../../firebase';
import { addDoc, arrayUnion, setDoc, doc, getDoc, increment, runTransaction,updateDoc,Timestamp } from "@firebase/firestore";
import Hashids from 'hashids'
import { stringify } from "@firebase/util";


async function AddVirtualClass(data,program){
    const hashids = new Hashids('',5)
    let error = ''
    let success='';
    let code="";
    
    
    
        //references
        const createdAt = timestamp;
        
        // Create a reference to the SF doc.
        var programRef = doc(db,"courses",program.id)
        var logRef = doc(db,"log",'all')
        var countRef = doc(db,"MC","count")
       

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(programRef);
                const countDoc = await transaction.get(countRef);

                if (!sfDoc.exists()) {
                  throw "Class does not exist!";
                }

                var newCount = (countDoc.data().virutalClassNumber||0) + 1;
                console.log(newCount)
                code=hashids.encode(newCount); 
            
                transaction.update(programRef, { 
                    classCount:increment(1),
                   classes: arrayUnion(code)
                 });
                transaction.update(countRef, { 
                  virutalClassNumber:newCount,
                 });
                transaction.set(doc(db,"vclasses",code), { 
                    ...data,
                    courseId:program.id,
                    closed: true, // indicates closed classes
                    id:code,
                    class:[],
                    course:program,
                    programId:program.programId,
                    location:'virtual',
                    minage:parseInt(data.minage),
                    order: parseInt(data.datefororder.replace(/\\/g, '').replaceAll('-', '')),
                    createdAt
                 });
                

              });
              // console.log(timestamp.fromDate(data.date))
              console.log("Session Created successfully!");
              success = true;
            
            } catch (e) {
              console.log("Registration failed: ", e);
              error = "Registration failed! ";
             
             await setDoc(doc(db,"log",data.date.replace(/\\/g, '')), {
                createdAt,
                log: "Registration failed",
                details : stringify(e),
                ...data

            });
            }

       
    return {error,success}

}

export default AddVirtualClass;