import React, { useState } from 'react';
import { Row,Container,Col } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";

//visible header that contains LOGO and Navigation Icon
import "./Info.css";
import "../../assets/css/custom.css";



function Info() {
    return(
        <section className="about hero-caption secPadding">

                <div className="container">
            
            <div className="row " >
                        <div className="col-sm-12">
            <h2>Welcome to <span><strong>NEXGEN</strong> Educational Hub</span> </h2>
        <p>
        Nexgen Educational Hub is the leading educational institute in Ghana visioned at equipping the next generation with relevant skills for the future. Our flagship programme in robotics for kids (Nexbot) has helped many youngsters develop innovative and problem-solving skills at an early stage and forged a trajectory for the pursuance of related fields of study at a higher level.
        </p>
            </div>
            
                    </div>

                </div>
            
        </section>


    );
}
export default Info;











