import React, { useEffect, useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col,Button, Spinner } from 'react-bootstrap';

import ViewModal from '../../modal/viewModal';
import AddUserForm from '../../Component/Forms/addUserForm'
// import Button from '@restart/ui/esm/Button';

import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import SessionCard from '../../Component/SessionCard';
import { courses, programId } from '../../coursedata';
import SessionDivs from './SessionDivs';





 function DronePilotingSessions() {
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [loading,setLoading] = useState(false);
  const [sect, setSect] = useState()
 



  useEffect(()=>{
      const fetchData =async()=>{
        setLoading(true)

      let classesdata = []
      const q = query(collection(db, "courses"), where("programId", "==", programId.dronePiloting));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      setLoading(false)
      }

        fetchData();
  },[])


  
  return(
        
        
<section className="sessions pricing  droneP  py-5 bg-white">
  <div className="container">
  <div className="heading">
  <span class="badge  bg-warning badge-warning badge-pill ">New</span><h1 className="title text-black" id="pricing">Drone Coding & Piloting </h1>
          <div className="separator"></div>
           
            <br/>	
          </div> 
    <div className="row">
    <div className='col-md-7 mx-0 my-auto'>
        <p className='text-justify lead text-black mb-2'>
        Drone technology has introduced new heights to the explorative spirit of kids.     
        </p>
        <p className='text-justify lead text-black mb-2'>
        Children can now stimulate their interest in technology by flying drones, practice hand-eye-brain coordination, improve adaptability and problem-solving abilities, learn safe flying skills, learn to code drones and develop a sense of responsibility in the fields of STEM. 
        So why not let them?

        <br/>
        </p>
        <p className='text-justify lead text-black text-bold '>
        Enroll your ward in our intensive <span className='fw-bold '>8-week </span>drone coding & piloting programme now and let's turn their curiosity into technological exploration.

        </p>
    </div>
    <div className='col-md-4 m-auto'>

        {
          sect && sect.map ( (element, i ) => {
            return   <SessionDivs type="drone" loading  sect title=''/>

            })

        }
        {
            (loading && !sect)   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              <p>Loading Available Sessions</p>
              </div>

        }
    </div>
      
    </div>
  </div>
</section>
    );
}
export default DronePilotingSessions;












