import React, { useEffect, useRef, useState } from "react";
// router dom
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import { useAuth } from "../../../contexts/AuthContext";

// css import
import "../Admin/UserAuth/Auth.css";

// boostraP IMPOTS
import { Container, Row, Col, Button, Form, Alert, Tabs, Tab, ListGroup } from "react-bootstrap";
import { collection, doc, getDoc, query, where,toDate, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { parseISO } from "date-fns";
import RenewHubUserForm from "../../Component/Forms/renewHubForm";
// import { useAuth } from "../../../Component/Context/AuthContext";
// import { useAuth } from "../../../../Components/Context/AuthContext";
import ViewModal from "../../modal/viewModal";
import AddHubUserForm from "../../Component/Forms/addHubUserForm";
import Hubslider from "../../Component/Helpers/HubSlider";
import { usePartnerContext } from "../../Component/Context/PartnerContext";
// image imports
// import Image from "../../../images/img-2.png";
// import { TutorAuthHeader } from "./TutorAuthHeader";


// header import

export default function Partnerlogin() {
  const idRef = useRef();
  const codeRef = useRef();
 const {setCurrentPartner,currentPartner} = usePartnerContext();
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [show1,setShow1] = useState(false);
  // const history = useNavigate();

  let location = useLocation();

  // let { from } = location.state || { from: { pathname: "/dashboard" } };


  async function handleSubmit(e) {
    e.preventDefault();

   
    const fetchpartnersData =async()=>{
      //LOGIN FUNCTIONS
      console.log("---> calling db");
          setLoading(true)
          let q = ''
          let partnerdata = []
       
             q = query(collection(db, "partners"),where('code','==',codeRef.current.value),where('id',"==",idRef.current.value));
             


      const  querySnapshot = await getDocs(q);
      if(querySnapshot.docs.length > 0){
        
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        partnerdata.push(doc.data())
      });   
      setData(partnerdata[0]);
              setCurrentPartner(partnerdata[0]);
      }else{
            console.log("No such document!");
              setError("Login Failed!...Incorrect details.")
      }
      
 
        

         
         //  splitsession(classesdata);
         setLoading(false)

        }

    try {
      setError("");
      setLoading(true);

        // await login(emailRef.current.value, passwordRef.current.value);
        // FETCH DATA OF USER
        fetchpartnersData(idRef)
        console.log("completed login, going to verify")

      //               await verifyUser();
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Row>
        {" "}

     
        <Col  className="loginsideimg-S">
          <Container fluid className="my-auto my-auto">
            
            <Row className="height-full-S">
              <Col sx={12} sm={10} md={7} lg={5}  className="mx-auto my-auto text-center container">
               
                <h5 className="text-primary m-4"> Partner Access Zone</h5>
                <p>Please enter your partner details below</p>
               
               <div></div>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
               
                  <Form.Group  className="row align-self-center vertical-align-center">
                    <Form.Label className="col-12 col-md-4 text-start"> Partner Id</Form.Label>
                    <Form.Control 
                      className="form-input col-12 col-md my-2 text-center"
                      type="text"
                      placeholder="Partner Id"
                      ref={idRef}
                      required
                    />
                  </Form.Group>
                  <Form.Group  className="row align-self-center vertical-align-center">
                    <Form.Label className="col-12 col-md-4 text-start"> Partner Code</Form.Label>
                    <Form.Control 
                      className="form-input col-12 col-md my-2 text-center"
                      type="text"
                      placeholder="XXXXX"
                      ref={codeRef}
                      required
                    />
                  </Form.Group>
                  
                  
                  <Button
                    disabled={loading}
                    variant="primary"
                    className="m-auto jnow"
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Form>
                <p className="head text-muted p-1 mx-auto d-block">
                Don't have an account yet? 
                {/* <Link to="../#hub" className="text-success "> Sign up here.</Link> */}
                <hr/>
                
                <button onClick={()=>setShow1(true)} className='btn btn-dark jnow mx-auto '>Join the hub</button>
                </p>
              </Col>
             
              
            </Row>
            <Hubslider/>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
