import React, { useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col, } from 'react-bootstrap';
import oneheartlogo from "../../assets/img/oh-hod.jpg";
import fahad from "../../assets/img/Farhad-new.jpg";
import man from "../../assets/img/man.png";
import JCSSHeadImage from "../../assets/img/JCSSTestimonial.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
function Testimonials() {

	const settings = {
		// dots: true,
		autoplay: true,
		infinite: true,
		speed: 700,
		slidesToShow: 1,
		slidesToScroll: 1
	  };

    return(
        
		<section className="default-bg bg-dark secPadding">
			
		<div className="container">
		  <div className="row">
			<div className='col-md-offset-2 col-md-8 text-center mx-auto'>
			<h2>Testimonials</h2>
			</div>
		  </div>
		  <div className='row'>
			<div className='col-md-offset-2 col-md-8 mx-auto'>
			  
				  <Slider {...settings}>

				  <div className="item active">
					  <blockquote>
					  <div className="row">
					  <div className="col-12 col-sm-12 col-md-3 col-lg-3 text-md-end text-center">
						  <img className="img-circle circle rounded-circle mx-auto" src={oneheartlogo} style={{width: '100px' , height: '100px'}}/>
						</div>
						<div className="col-12 col-sm-12  col-md-9 col-lg-9">
						  <p>As a head of a school, I can attest to how much my students enjoyed the programme. Nexgen really has a well-structured programme.
						  </p>
						  <small>
							  - Elizabeth Choi (Director, One Heart Africa Academy )</small>
						</div>
					  </div>
					  </blockquote>
				  </div>
				  {/* <!-- Quote 2 --> */}
				  <div className="item active">
				  <blockquote>
					  <div className="row">
					  <div className="col-12 col-sm-12 col-md-3 col-lg-3 text-md-end text-center">
						  <img className="img-circle circle rounded-circle mx-auto" src={fahad} style={{width: '100px' , height: '100px'}}/>
						</div>
						<div className="col-12 col-sm-12  col-md-9 col-lg-9">
						  <p>
						  It may sound cliché to say they are the best, but I can't think of anywhere better.
						  </p>
						  <small>
						  - Fahad Waqas (Medical Technologist)
							  </small>
						</div>
					  </div>
					  </blockquote>
				  </div>
				  <div className="item active">
				  <blockquote>
					  <div className="row">
					  <div className="col-12 col-sm-12 col-md-3 col-lg-3 text-md-end text-center">
						  <img className="img-circle circle rounded-circle mx-auto" src={JCSSHeadImage} style={{width: '100px' , height: '100px'}}/>
						</div>
						<div className="col-12 col-sm-12  col-md-9 col-lg-9">
						  <p>
						  Tech Tuesdays with Nexgen was a huge hit at Jemison Carver STEM International School.  Nexgen is a proven trailblazer in bringing today's relevant skills of coding and robotics to the doorsteps of students across Ghana.   
						  </p>
						  <small>
						  - Laliet Gyesi, (Head of School - Jemison Carver STEM International School)
							  </small>
						</div>
					  </div>
					  </blockquote>
				  </div>
				  <div className="item active">
				  <blockquote>
					  <div className="row">
					  <div className="col-12 col-sm-12 col-md-3 col-lg-3 text-md-end text-center">
						  <img className="img-circle circle rounded-circle mx-auto" src={man} style={{width: '100px' , height: '100px'}}/>
						</div>
						<div className="col-12 col-sm-12  col-md-9 col-lg-9">
						  <p>
						  It is not just about the knowledge the kids gain. Anyone can offer that. Nexgen's program nurtures innovativeness in these kids at an early stage. And that's what I love about it.
						  </p>
						  <small>
						  - Benjamin Nketiah, (Electrical Engineer - NEMA Solutions Ltd.)
							  </small>
						</div>
					  </div>
					  </blockquote>
				  </div>

				  </Slider>
				  {/* <!-- Quote 3 --> */}
				  {/* <div className="item">
					<blockquote>
					  <div className="row">
						<div className="col-sm-3 text-center">
						  <img className="img-circle" src="https://s3.amazonaws.com/uifaces/faces/twitter/keizgoesboom/128.jpg" style={{width: '100px' , height: '100px'}}/>
						</div>
						<div className="col-sm-9">
						  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rutrum elit in arcu blandit, eget pretium nisl accumsan. Sed ultricies commodo tortor, eu pretium mauris.</p>
						  <small>Someone famous</small>
						</div>
					  </div>
					</blockquote>
				  </div> */}
				{/* </div>               */}
			  {/* </div>                           */}
			</div>
		  </div>
		</div>
				</section>
    );
}
export default Testimonials;












