import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link,useHistory, useParams } from 'react-router-dom';
// import { useAuth } from '../../../components/Context/AuthContext';
// import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"
import ClassList from '../../../Component/Table/Table';
import { useAuth } from '../../../Component/Context/AuthContext';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db, functions } from '../../../firebase';
import StudentList from '../../../Component/Table/StudentsTable';
import CloseClass from '../../../Component/Hooks/useCloseClass';
import MakeClassLive from '../../../Component/Hooks/useMakeClassLive';
import ViewModal from '../../../modal/viewModal';
import AddUserFormAdmin from '../../../Component/Forms/addUserFormAdmin';
import StudentMailList from '../../../Component/Table/StudentsMailTable';
import { getFunctions, httpsCallable } from 'firebase/functions';


const AllStudentsDetails =()=>{
    let params = useParams();
    // const classId = params.classId;
    // const locationId = params.locationId;
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID ,currentUser,userProfile} = useAuth()
    const [show,setShow] = useState(false);
    const [show1,setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    const [sect, setSect] = useState()
    const [ classData,setClassData] = useState()
    const [ studentData,setStudentData] = useState()
    const [ activeId,setActiveId] = useState()
    const [data, setData] = useState()
    const [vdata, setVData] = useState()
    const [sdata, setSData] = useState()
    const [jdata, setJData] = useState()
    const [location, setLocation] = useState()
    const [mails, setMails] = useState([])
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

  const UpdateMail = (value) => {
    if (mails.includes(value)) {
      // If the value already exists, remove it from the array
      const updatedMails = mails.filter(mail => mail !== value);
      setMails(updatedMails);
      console.log(`${value} was removed from the array`);
    } else {
      // If the value does not exist, add it to the array
      setMails([...mails, value]);
      console.log(`${value} was added to the array`);
    }
   
  };
  const GoLive = async()=>{
    // console.log(userID);
    setLoading(true)

    const {error,success} = await MakeClassLive(userID,classData);

    setError(error);
    setSuccess(success);
    setLoading(false)
    
    // fetchClassData();

}
  const GoOffline = async()=>{
    // console.log(userID);
    setLoading(true)

    const {error,success} = await CloseClass(userID,classData);

    setError(error);
    setSuccess(success);
    setLoading(false)
    
    // fetchClassData();

}

const sendhypeMail = () =>{
  const functions = getFunctions();

var SendMail = httpsCallable(functions, 'sendHubHypeMail');

SendMail(mails)
  .then((result) => {
    // Read result of the Cloud Function.
    console.log("mail sent")
  })
  .catch((error) => {
    // Getting the Error details.
    var code = error.code;
    var message = error.message;
    var details = error.details;
    // ...

    console.log(message)
  });
}
  useEffect(()=>{
//     const fetchLocationData =async()=>{
//       setLoading(true)

//       let locationData = {}
//      const q = doc(db, "branches",locationId);
// //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
    
//       const docSnap = await getDoc(q);

//       if (docSnap.exists()) {
//       console.log("Document data:", docSnap.data());
//       locationData = docSnap.data();
//       } else {
//       // doc.data() will be undefined in this case
//       console.log("No such document!");
//       setError('Branch Not Found!')
//       }
    
//     await setLocation(locationData);
//     fetchClassData();
//     setLoading(false)
//     }

    // const fetchClassData =async()=>{
    //     setLoading(true)

    //     let seclassData = {}
    //     const q = doc(db, "classes",classId);
    // //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
        
    //         const docSnap = await getDoc(q);

    //         if (docSnap.exists()){
    //         console.log("Document data:", docSnap.data());
    //         seclassData = docSnap.data();
    //         setClassData(seclassData);

    //         } else {
    //         // doc.data() will be undefined in this case
    //         console.log("No such document!");
    //         setError('Class Not Found!')
    //         }
        
    //     // splitsession(classesdata);
    //     setLoading(false)
    //     fetchstudentsData(seclassData.class)

    //     }
   

             const fetchstudentsData =async(classData)=>{
                     setLoading(true)
                     let q = ''
                     let studentsdata = []
                    
                       
                      q = query(collection(db, "students"));
                       

                    //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
                        const  querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            console.log(doc.id, "new => ", doc.data());
                            studentsdata.push(doc.data())
                        });
                        
                    setSect(studentsdata);
                    //  splitsession(classesdata);
                    setLoading(false)

                   }
      
          
              fetchstudentsData()
            // fetchLocationData();

      
        },[])
      

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/myelections" }} active >
                    Class
                </Breadcrumb.Item>
                
            </Breadcrumb>
            <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={ logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={6}>
                        <h5>Welcome to {/*{classData && classData.session}*/} all students</h5>
                        <h5>Welcome to {/*classData && classData.class.length*/} </h5> 
                        {/* <p>{classData && classData.date} | {classData && classData.start } - {classData && classData.session}</p> */}
                    </Col>

                    <Col md={3}  className="text-end mx-auto my-auto">
                    {/* <Button className="m-1" onClick={()=>setShow1(true)}  >Add student</Button> */}  
                    {/* // <ViewModal
                    // handleClose={() => setShow1(false)}
										// 		show={show1}
										// 		button="close"
										// 		title={'Adding student'}>
										// 		<div className="row">	
                    //     {/* <AddUserFormAdmin type={classData} location={location}/> 
                    //     {/* <AddUserFormVirtual type={data} /> 
										</div>
                    // </ViewModal> */}
                     <Button className="m-1 btn btn-secondary" onClick={()=>sendhypeMail({})}  >Send Hub Hype mail</Button> 
                    </Col>
                    
             </HeaderBanner>
             {(!loading && !error && sect) && <StudentMailList  students={sect} UpdateMail={UpdateMail} mails={mails}/>}
             {loading && <Spinner animation="grow" variant="success"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}

        </Container>
    

    </Body>
    )
}

export default AllStudentsDetails;