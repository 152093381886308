import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Breadcrumb, Badge} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useHistory, useParams ,Link} from 'react-router-dom';
// import { BecomeProWidget } from '../../../components/BecomeProWidget';
// import ClassList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from './PartnerDashboardElement';
import logo from "../../../assets/img/logo.png"
import ViewModal from '../../../modal/viewModal';
import AddClassForm from '../../../Component/Forms/addClassForm';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import ClassList from '../../../Component/Table/Table';
import { useTestContext } from '../../../Component/Context/TestContext';


const TestDashboard =()=>{

    const [success,setSuccess] = useState(false);
    const [show,setShow] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {currentTest} = useTestContext()

    const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [sect, setSect] = useState()
  const [students, setStudents] = useState()
  const [SATstudents, setSATStudents] = useState()
  const [IELTSstudents, setIELTSStudents] = useState()
  const [location,setLocation] = useState()
  const [ activeId,setActiveId] = useState()
  const [data, setData] = useState()
  const [vdata, setVData] = useState()
  const [sdata, setSData] = useState()
  const [jdata, setJData] = useState()
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

 const fetchLocationClassesData =async(locationId)=>{
   setActiveId(locationId)
        setLoading(true)

        let classesdata = []
       const q = query(collection(db, "classes"), where("closed", "==", false),where('locationId',"==",locationId.id));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      splitsession(classesdata);
      setLoading(false)
      }

      const fetchclosedClassesData =async(locationId)=>{
        setActiveId(locationId)
             setLoading(true)
     
             let classesdata = []
            const q = query(collection(db, "classes"), where("closed", "==", true), where("locationId","==",locationId.id));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             classesdata.push(doc.data())
           });
           
           setSect(classesdata);
           splitsession(classesdata);
           setLoading(false)
           }
 

  useEffect(()=>{
 
    const fetchAllSATStudents =async()=>{
             setLoading(true)
     
             let stiduentsData = []
            let  q = query(collection(db, "SATstudents"));
           
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             stiduentsData.push(doc.data())
           });
           
           setSATStudents(stiduentsData);
           setLoading(false)
           }
    const fetchAllIELTSStudents =async(locationId)=>{
             setLoading(true)
     
             let stiduentsData = []
            let  q = query(collection(db, "IELTSstudents"));
           
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             stiduentsData.push(doc.data())
           });
           
           setIELTSStudents(stiduentsData);
           setLoading(false)
           }

     
        fetchAllIELTSStudents();
        fetchAllSATStudents();
        

  },[])


    const closeModal =()=>{
        setShow(false)
        // FetchProduct()
      }

    
    

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/tests/dashboard" }} active>Dashboard</Breadcrumb.Item>
                
                
            </Breadcrumb>
             <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={(currentTest && currentTest.logo )|| logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                        Welcome
                        <h5> {currentTest && currentTest.name}</h5>
                        <p>This is your dashboard</p>
                    </Col>
                   
             </HeaderBanner>
             
             

             {/* activity summary bar */}
             <Row className="bg-white p-2 m-auto mx-auto mt-2">
            
            
            <Col className="bg-white m-auto mx-auto mt-2">
                <Card  className=" border-info p-4">
                     <Row>
                        <Col md={6} lg={8}><h1>{SATstudents && SATstudents.length}</h1>SAT Students</Col>
                       
                     </Row>
                 </Card>
            </Col>
            <Col className="bg-white m-auto mx-auto mt-2">
            <Card  className=" border-info  p-4">
                     <Row>
                        <Col md={6} lg={8}><h1>{IELTSstudents && IELTSstudents.length}</h1> IELTS Students</Col>
                        
                     </Row>
                 </Card>
            </Col>
          </Row>
             {/* table for branches under ship*/}
           <Row className=''>
           <h1>TESTS</h1>
           <div>
           <Link to={"sat"} className ={'branch-btn text-warning fw-bolder text-uppercase m-2 '} >SAT  <Badge bg="warning" >Live</Badge>
             </Link>
           <Link to={"ielts"} className ={'branch-btn text-warning fw-bolder text-uppercase m-2 '} >IELTS  <Badge bg="warning" >Live</Badge>
             </Link>

           </div>           <hr className='bg-info'/>

          <div>
             {
          location && location.map ( (element, i ) => {
            return <Link to={element.id}
             className ={'branch-btn text-primary fw-bolder text-uppercase m-2 '} >{element.location} </Link>
            })
            }
            </div>
             
               
             </Row>
             {loading && <Spinner className="text-center my-auto mx-auto" animation="border" variant="info"/>}
             {(error ) && <Alert className="text-center" variant="danger">{error}</Alert>}


        </Container>
    

    </Body>
    )
}


export default TestDashboard;