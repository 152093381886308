import React, { useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col, } from 'react-bootstrap';
import oneheartlogo from "../../assets/img/oh-hod.jpg";
import fahad from "../../assets/img/Farhad.jpeg";
import man from "../../assets/img/man.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PartnerSlider from '../../Component/Helpers/PartnerSlider';

function Partners() {

	const settings = {
		// dots: true,
		autoplay: true,
		infinite: true,
		speed: 700,
		slidesToShow: 1,
		slidesToScroll: 1
	  };

    return(
        
		<section className="default-b secPadding text-info">
			
		<div className="container">
		  <div className="row">
			<div className='col-md-offset-2 col-md-8 text-center mx-auto'>
			<h2>Partners</h2>
			</div>
		  </div> 
		</div>
		<div className='row col-11 mx-auto'>
			<PartnerSlider/>
		</div>
		  

		 
		</section>
    );
}
export default Partners;












