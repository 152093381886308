import React, { useState, useEffect } from 'react';
import ViewModal from '../modal/viewModal';
import { Spinner,Container, Row,Card,Col,Button } from 'react-bootstrap';
import AddUserForm from './Forms/addUserForm';

import "../views/Landing/Services.css";
import AddUserFormVirtual from './Forms/addUserFormvirtual';
import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../firebase';

const SessionJuniorCard=({data,ukey,courseDetail})=>{
      const [show1,setShow1] = useState(false);
  const [ course,setCourse] = useState()

      const [ location,setLocation] = useState()
      const [loading,setLoading] = useState(false);
      
  var price =  courseDetail?.price;
  var minage =  courseDetail?.minAge;


     



      useEffect(()=>{
        const fetchLocationData =async()=>{
           setLoading(true)
   
           let locationData = []
          const q = query(collection(db, "branches"), where("closed", "==", false));
   //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
         const  querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
           // doc.data() is never undefined for query doc snapshots
           console.log(doc.id, " => ", doc.data());
           locationData.push(doc.data())
         });
         if (locationData.length>1){
           setLocation(locationData);
         }else{
           setLocation(locationData);
   
         }
         
         // splitsession(classesdata);
         setLoading(false)
         }
           fetchLocationData();
     },[])
    
     useEffect(()=>{
      const fetchCourseData =async()=>{
         setLoading(true)
  
         let courseData = []
        const q = query(collection(db, "courses"),where("programId", "==", courseDetail.programId));
  //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");where("closed", "==", false),
       const  querySnapshot = await getDocs(q);
       querySnapshot.forEach((doc) => {
         // doc.data() is never undefined for query doc snapshots
         console.log(doc.id, " => ", doc.data());
         courseData.push(doc.data())
       });
       if (courseData.length>1){
         setCourse(courseData);
       }else{
         setCourse(courseData);
       }
       
       // splitsession(classesdata);
       setLoading(false)
       }
         fetchCourseData();
   },[])
      return(
        <div>
        {loading ?
          <Spinner className="mx-auto" animation="border" variant="info"/> 

          :

        
        <div className="">
        <h5 className="card-title text-white text-uppercase text-center">{ukey === 0 ? "Up Next" : '' }</h5>

        <div className={ukey === 0 ? 'card mb-5 mb-lg-0':'bg-warning card mb-5 mb-lg-0'} >
          <div className="card-body">
            <h6 className="card-price text-center">Robotics (Junior session)</h6>
    
                  
            <hr/>
            <h5 className="card-title text-uppercase text-center">Age</h5>
            <h6 className="card-title text-center">{minage}+ <span className="period">years</span></h6>
            <hr/>
            {/* <h5 className="card-title text-primary fw-bolder text-uppercase text-center">Enroll For a 23.5% Discount</h5>
            <h6 className="card-title text-center text-decoration-line-through text-danger"><span className="period">GH₵850</span></h6>    */}
            <h6 className="card-price text-center ">GH₵{price} <span className="period">only</span></h6>   
            <ul className="fa-ul">
                     
            </ul>
            <div className="d-grid">
              <Button  onClick={()=>setShow1(true)} className="btn btn-primary text-uppercase">Enroll Now</Button>
              
              <ViewModal
												handleClose={() => setShow1(false)}
												show={show1}
												button="close"
												title={"Robotics (Junior session)"}
                        >
												<div className="row">	
												<AddUserForm type={data} minage={minage}  location={location} course={course && course[0]}/>
											</div>
											</ViewModal>
            </div>


          </div>
        </div>
        
      </div>
          }
    </div>

)}

export default SessionJuniorCard;