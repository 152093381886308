import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';

// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
// import { firestore, storageRef } from '../../../firebase';

import { useAuth } from '../Context/AuthContext';
import AddClass from '../Hooks/useAddClass';
import AddHub from '../Hooks/useAddHub';
// import 'bootstrap/dist/css/bootstrap.min.css';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const AddHubForm =({closeModal,branch})=>{
     // files to upload
  const [data, setData] = useState([]);
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const { userID } = useAuth()


  const UpdateData =(item,value)=>{
    if(item === 'session'){
        if (value === 'morning') {
           setData(data => ({...data, 
            period:value,
              start : '9:30',
              startSubscript:'am',
              end : '11:00',
              endSubscript:'am',
  
          }))}
        if (value === 'midday') {
           setData(data => ({...data, 
            period:value,
              start : '11:30',
              startSubscript:'am',
              end : '1:00',
              endSubscript:'pm',
          }))}
        if (value === 'afternoon') {
           setData(data => ({...data, 
            period:value,
              start : '1:30',
              startSubscript:'pm',
              end : '3:00',
              endSubscript:'pm',
  
          }))}
        if (value === 'evening') {
           setData(data => ({...data, 
              period:value,
              start : '3:30',
              startSubscript:'pm',
              end : '5:00',
              endSubscript:'pm',
          })) }
        }
      if(item == 'minage'){

      
      if (value == 5) {
         setData(data => ({...data, 
            minage:5,
            session:'Junior',
            // start : '3:00',
            // startSubscript:'pm',
            // end : '5:00',
            // endSubscript:'pm',
        }))}
      if (value == 9) {
         setData(data => ({...data,
            minage:9,
            session:'Senior',
            // start : '3:00',
            // startSubscript:'pm',
            // end : '5:00',
            // endSubscript:'pm',
        }))}
      }
    else{
        setData(data => ({...data, 
            [item]:value}))
    }
    setFormError(formError => ({...formError, [item]:''}))
  }
  

    const eventHandler = (event) => {
        let val = event.target.value;
        let nam = event.target.name;
        UpdateData(nam,val)
    };


    const findFormErrors = () => {
        const newErrors = {}
        // name errors
        // if ( !data.session || data.session === '' ) newErrors.name = 'Cannot be blank!'
        // else if ( data.session.length > 100 ) newErrors.name = 'session is too long!'
        // // food errors
        // if ( !data.date || data.date === '' ) newErrors.date = 'Add a valid date!'
        // if ( !data.datefororder || data.datefororder === '' ) newErrors.date = 'Add a valid date!'
        if ( !data.minage || data.minage === '' ) newErrors.minage = 'Select Session!'
    
        

        return newErrors;
    }

    
    const Submit = async ()=>{ 
        // Add Product
        const {error,success} = await AddHub(data,branch);
        
        setMainError(error);
        setSuccess(success);
        setData('');
        // closeModal()
        setLoading(false);

   }

     
    
    const Proceed = async () => {
        // event.preventDefault();
    
        const newErrors = findFormErrors()
            // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setFormError(newErrors)
            } else {
            // No errors! Put any logic here for the form submission!
            setLoading(true)
                // uploadImage();
                Submit();
                // continue with other rendering
            }
        
    };

    const output = <>
           <Form noValidate validated={validated} onSubmit={Proceed} inline>

           <Form.Group className="row" controlId="validationCustom04">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Session
                    </Form.Label>
                    <InputGroup className="form-input col" >
                    
                    <Form.Control
                    as="select"
                    className=" col"
                    name="session"
                    value={data.period}
                    onChange={eventHandler}
                    isInvalid={ !!formError.session }
                    
                    >
                    <option value=''>Select session</option>
                    <option value='morning'>morning </option>
                    <option  value='midday'>midday</option>
                    <option  value='afternoon'>Afternoon</option>
                    <option  value='evening'>Evening</option>
                    </Form.Control>
                    </InputGroup>
                </Form.Group>

                
                {/* election TITLE */}

                {/* <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
                    Date in words
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="date"
                    required ={true}
                    value={data.date}
                    onChange={eventHandler}
                    isInvalid={ !!formError.date }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.date}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>  */}
                {/* <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto my-auto">
                    Date from calender
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="date"
                    name="datefororder"
                    required ={true}
                    value={data.datefororder}
                    onChange={eventHandler}
                    isInvalid={ !!formError.datefororder }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.date}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>  */}

                <Form.Group className="row" controlId="validationCustom04">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Level
                    </Form.Label>
                    <InputGroup className="form-input col" >
                    
                    <Form.Control
                    as="select"
                    className="col"
                    name="minage"
                    value={data.minage}
                    onChange={eventHandler}
                    isInvalid={ !!formError.minage }
                    
                    >
                    <option value=''>Select session</option>
                    <option value={5} >Junior session (5+)</option>
                    <option value={9} >Senior session (9+)</option>
                    
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                {formError.minage}
                </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

           

       
   

                <Col className="text-center">
                    <Button
                    onClick={()=>Proceed()}
                    className=" btn-success text-center"
                    >
                    Proceed {(loading && !error)   &&  <Spinner animation="border" variant="white"/> }
                    </Button>

                </Col>
                </Form>

    </>

    return(
        <>
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {success && <Alert variant="success"><h3 className="mx-auto">{"Product added Succesfuly"}</h3></Alert> }
            {(!error && !success)   &&  output }

            
        </>
     
    );
}

export default AddHubForm;