import React, { useEffect, useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Coding.css";
import { Container, Row,Card,Col,Spinner } from 'react-bootstrap';
import { FaLandmark, FaLocationArrow, FaMapMarked, FaMapMarkerAlt } from 'react-icons/fa';
import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';

export default function Branches() {
	const [ location,setLocation] = useState()
	const [loading,setLoading] = useState(true);

	useEffect(()=>{
		const fetchLocationData =async()=>{
		   setLoading(true)
	
		   let locationData = []
		  const q = query(collection(db, "branches"), where("closed", "==", false));
	//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
		 const  querySnapshot = await getDocs(q);
		 querySnapshot.forEach((doc) => {
		   // doc.data() is never undefined for query doc snapshots
		   console.log(doc.id, " => ", doc.data());
		   locationData.push(doc.data())
		 });
		 if (locationData.length>1){
		   setLocation(locationData);
		 }else{
		   setLocation(locationData);
		 }
		 
		 // splitsession(classesdata);
		 setLoading(false)
		 }
		   fetchLocationData();
	 },[])


    return(
		<>
			{
				loading ? 
				<Spinner className="mx-auto" animation="border" variant="light"/> 
				:
			<section className="codingprice services transparent-bg  pclear bg-dark text-light secPadding">
				<div className="container text-light" data-animation-effect="fadeIn">
					<h1 id="services" className="title text-center text-light">Training Centers</h1>
					<div className="space"></div>
					<div className="row">
					{  location && location.map ( (element, i ) => {
						return (<div className="col" key={i}>
							<div className="media block-list">
								<div className=" text-center">
									<FaMapMarkerAlt className='text-warning' size={"80"}/>
								</div>
								<div className="media-body text-center">
									<h3 className="media-heading text-light">{element.location}</h3>
									<blockquote>
										<p>
											{element.name}
										</p>
										<p className='H4'>
											<a target="_blank" className='btn btn-primary' href={element.gps}> Click here for direction </a>
										</p>
									</blockquote>
								</div>
							</div>
						</div>)
						})
						}
					</div>
					
				</div>			  
			</section>
			}
		</>
    );
}












