import React from 'react'
import "./IELTSlogo.css"

export default function IELTSlogo() {
  return (
    <div class="w-content my-3 text-center text-white iELTS p-1">
        <span class="bc-brand-logo" href="/"></span> <span class="ielts-brand-logo" href="/"></span>
        <p className='fw-bold'>Registration Centre</p>

    </div>
  )
}