import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb, Tabs, Tab, ListGroup} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
// import { useAuth } from '../../../Components/Context/AuthContext';
// import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
// import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"
import ClassList from '../../../Component/Table/Table';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../Component/Context/AuthContext';
import ClosedClasses from './ClosedClasses';
import ViewModal from '../../../modal/viewModal';
import AddClassForm from '../../../Component/Forms/addClassForm';
import AddHubForm from '../../../Component/Forms/addHubForm';
import UpcomingClasses from './UpcomingClasses';
import HubSessions from './HubSessions';
import AddVClassForm from '../../../Component/Forms/addVirtualClassForm';


const SingleCourse =()=>{
    let params = useParams();
    const courseId = params.courseId;
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const [show1,setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [sect, setSect] = useState()
    const [ location,setLocation] = useState()
    const [ activeId,setActiveId] = useState()
    const [data, setData] = useState()
    const [vdata, setVData] = useState()
    const [sdata, setSData] = useState()
    const [jdata, setJData] = useState()
    const [key,setKey] = useState('class')


  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

 const fetchLocationClassesData =async()=>{
   setActiveId(courseId)
        setLoading(true)

        let classesdata = []
       const q = query(collection(db, "classes"), where("closed", "==", false),where('courseId',"==",courseId.id));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      splitsession(classesdata);
      setLoading(false)
      }

      const fetchclosedClassesData =async()=>{
        setActiveId(courseId)
             setLoading(true)
     
             let classesdata = []
            const q = query(collection(db, "classes"), where("closed", "==", true), where("courseId","==",courseId));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             classesdata.push(doc.data())
           });
           
           setSect(classesdata);
           splitsession(classesdata);
           setLoading(false)
           }
 


    useEffect(()=>{
        const fetchAllClassesData =async()=>{
            setActiveId(courseId)
                 setLoading(true)
         
                 let classesdata = []
                 const q = query(collection(db, "vclasses"), where("closed", "==", false), where("courseId", "==", courseId));
                 //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
               const  querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                 // doc.data() is never undefined for query doc snapshots
                 console.log(doc.id, " => ", doc.data());
                 classesdata.push(doc.data())
               });
               
               setSect(classesdata);
               splitsession(classesdata);
               setLoading(false)
               }
    
         const fetchLocationData =async()=>{
            setLoading(true)
    
            let locationData = {}
           const q = doc(db, "courses",courseId);
    //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
          
            const docSnap = await getDoc(q);

            if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            locationData = docSnap.data();
            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setError('Course Not Found!')
            }
          
          await setLocation(locationData);
          // splitsession(classesdata);
          setLoading(false)
          }
            fetchLocationData();
            // fetchAllClassesData();
    
      },[])
    
    

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/courses" }}>Courses</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/courses/"+courseId }} active >
                    SingleCourse 
                </Breadcrumb.Item>
                
            </Breadcrumb>
            <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                      <h3>{location && location.name}</h3>
                        <p>{location && location.location}</p>
                    </Col>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <Button  onClick={()=>setShow1(true)} className="my-auto btn my-auto mx-auto"><BsPlus/> Add Class</Button>
                    </Col>
                    <ViewModal
                    handleClose={() => setShow1(false)}
												show={show1}
												button="close"
												title={'coding'}>
												<div className="row">	
                        <AddClassForm course={location} />
											</div>
                    </ViewModal>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <Button  onClick={()=>setShow2(true)} className="my-auto btn my-auto mx-auto"><BsPlus/> Add Hub</Button>
                    </Col>
                    <ViewModal
                    handleClose={() => setShow2(false)}
												show={show2}
												button="close"
												title={'Adding Hub'}>
												<div className="row">	
                        <AddHubForm course={location} />
                        												{/* <AddUserFormVirtual type={data} /> */}
											</div>
                    </ViewModal>
             </HeaderBanner>
             <Row>
             <Tabs 
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
             className="mb-5">
           
            <Tab eventKey="class" title="Classes">
              <UpcomingClasses courseId={courseId}/>
              <ClosedClasses courseId={courseId}/>
            </Tab>
            <Tab eventKey="hub" title="Innovators Hubs" className="">
            {/* <HubSessions courseId={courseId}/> */}

            </Tab>
           
          </Tabs>
             </Row>
             {loading && <Spinner animation="grow" variant="success"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}


             
        </Container>
    

    </Body>
    )
}

export default SingleCourse;