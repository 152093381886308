import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from "./AuthContext";


const PublicRoute = ({children, restricted}) => {
  const { currentUser } = useAuth();

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        
            currentUser && restricted ?
                <Navigate to="/dashboard" />
            : children
    );
};

export default PublicRoute;