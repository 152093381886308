import React, { useEffect, useState } from 'react';

//visible header that contains LOGO and Navigation Icon
import "../../views/Landing/Hub.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/img/NXTBOTSHOOTS/nxbimg55.jpg";
import slide2 from "../../assets/img/NXTBOTSHOOTS/nxbimg60.jpg";
import slide3 from "../../assets/img/NXTBOTSHOOTS/nxbimg36.jpg";
import slide4 from "../../assets/img/NXTBOTSHOOTS/nxbimg11.jpg";
import slide5 from "../../assets/img/NXTBOTSHOOTS/nxbimg38.jpg";
import slide6 from "../../assets/img/NXTBOTSHOOTS/nxbimg69.jpg";
import slide7 from "../../assets/img/NXTBOTSHOOTS/nxbimg45.jpg";
import slide8 from "../../assets/img/NXTBOTSHOOTS/nxbimg33.jpg";


 function Hubslider() {
 
 
      const settings = {
        // dots: true,
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,

        };



  
  return(
         
        
          <div className='slidewraper my-auto' >
            <Slider {...settings}>
            <div><img src={slide1}   alt="img" className='img-fluid' /></div>
            <div><img src={slide2}   alt="img" className='img-fluid' /></div>
            <div><img src={slide3}   alt="img" className='img-fluid' /></div>
            <div><img src={slide4}   alt="img" className='img-fluid' /></div>
            <div><img src={slide5}   alt="img" className='img-fluid' /></div>
            <div><img src={slide6}   alt="img" className='img-fluid' /></div>
            <div><img src={slide7}   alt="img" className='img-fluid' /></div>
            <div><img src={slide8}   alt="img" className='img-fluid' /></div>
           

          </Slider>
          </div>
      
    );
}
export default Hubslider;












