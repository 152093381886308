import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Badge} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
// import { useAuth } from '../Context/AuthContext';
import logo from '../../assets/img/logo.png'
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight } from '../../views/Admin/Dashboard/DashboardElement';

const TableRow =({classData})=> {

    return (
        <tr>
        <td className="text-center text-muted">#{classData.session} ({classData.start} - {classData.end})</td>
        <td>
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left mr-3">
                        <div className="widget-content-left">
                            <img width="40" className="rounded-circle" src={classData.img||logo} alt="Avatar"/>
                        </div>
                    </div>
                    <div className="widget-content-left flex2">
                        <div className="widget-heading">{classData.class.length}</div>
                    </div>
                </div>
            </div>
        </td>
        <td className="text-center">
            {classData.date}
            </td>
        <td className="text-center">
            <Badge bg="warning" text="dark" className="bg-warning">{classData.closed ? 'Closed':'Open'}</Badge>
        </td>
        <td className="text-center">
            <LinkButton to={classData.id} type="badge" role={"button"}  className="badge badge-primary badge-sm">Details</LinkButton>
        </td>
    </tr>
    );
  }

  const TableBody =({classData})=>{
     
    return(
        <tbody>
           {classData.map ( (element, i ) => {
             return <TableRow 
                        classData={element}
                         key = {i}
                        />
              })}
           
             
           </tbody>
         );
}


const ClassList =({classes,title})=>{
    const [classData,setClassData]= useState(classes);

    return(
                    
            //  {/* table for recent classes */}
             <Row className="bg-white p-2 m-auto mx-auto mt-2">
                 <div className="col-md-12">
                    <Card className="main-card mb-3 card">
                        <Card.Header className="bg-white">
                            <Row>
                            <Col sm={12} md={4}>{title}</Col>
                            {/* <ActionButtonRight className="col-sm-12 col-md-3 text-end ">
                                <div role="group" className="btn-group-sm btn-group">
                                    <Button active className=" btn btn-info">pending </Button>
                                    <Button className="btn btn-info">running</Button>
                                    <Button className="btn btn-info">Ended</Button>
                                </div>
                            </ActionButtonRight> */}
                            </Row>
                            
                        </Card.Header>
                        { !classData &&
                                       <Alert variant="warning" className="mx-auto my-2 text-center"> <strong>No classes Yet</strong> </Alert>
                               
                                }
                        { classData && 
                                
                                <div className="table-responsive">
                            <Table className="align-middle mb-0 table table-borderless table-striped table-hover text-center">
                                <thead>
                                    <tr>
                                        <th className="text-center">Session</th>
                                        <th className="text-center">Enrolled</th>
                                        <th>Time</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">More</th>
                                    </tr>
                                </thead>
                                
                                
                                { classData && <TableBody classData={classes}/>}
                            </Table>
                        </div>
                        }
                    </Card>
                </div>
            </Row>

    )
}


export default ClassList;