import React, { useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col, Tabs, Tab, } from 'react-bootstrap';
import ViewModal from '../../modal/viewModal';
import { gallery, galleryOhaa2 } from '../../Component/mock/mock';

const Photo = ({picture}) =>{
	const [show1,setShow1] = useState(false)

	return (
						
						<div className="col-sm-6 col-md-3 isotope-item app-development">
							<div className="image-box">
								<div className="overlay-container">
									<img src={picture.img} alt={picture.img}/>
									<a onClick={() => setShow1(true)} className="overlay" data-toggle="modal" data-target="#project-2">
										<i className="fa fa-search-plus"></i>
										 
									</a>
								</div>
								
								<a onClick={() => setShow1(true)} className="btn btn-default btn-block w-100" data-toggle="modal" data-target="#project-2">NEXBOT PHOTO</a>
							</div>
							{/*<!-- Modal -->*/}
							
											<ViewModal 
												handleClose={() => setShow1(false)}
												show={show1}
												button="close"
												title={picture.tagline}>
												<div className="row">												 
												<div className="col-md-12">
												<img src={picture.img} alt={picture.alt}/>
													<br/>
													{/* <h3>{picture.tagline}</h3> */}
													<p>{picture.description}</p>
												</div>
											</div>
											</ViewModal>
											
							
							{/*<!-- Modal end -->*/}
						</div>
	)
}
function Gallery() {
	const [show1,setShow1] = useState(false)
	const [show2,setShow2] = useState(false)
	const [show3,setShow3] = useState(false)
	const [key,setKey] = useState("ohaa")
	
	  
    return(
		<section className="section secPadding gallery">
		<div className="container">
			<h1 className="text-center title" id="portfolio">Gallery</h1>
			<div className="separator"></div>
			<br/>			
			<div className="row no-views" data-animation-effect="fadeIn">
				<Tabs
					id="controlled-tab-example"
					activeKey={key}
					onSelect={(k) => setKey(k)}
					className="mb-3"
					>
					<Tab eventKey="ohaa2" title="Exhibition 1">
					<div className="isotope-container row grid-space-20">
						{gallery.map((data,i)=>(
							<Photo picture ={data} key={i}/>
						))}
					</div>	
					</Tab>
					<Tab eventKey="ohaa" title="Exhibition 2">
						<div className="isotope-container row grid-space-20">
						{galleryOhaa2.map((data,i)=>(
							<Photo picture ={data} key={i}/>
						))}
					</div>	
					</Tab>

				</Tabs>
				<div className="col-md-12">

					{/*<!-- isotope filters start -->*/}
					
					{/*<!-- isotope filters end -->*/}

					{/*<!-- portfolio items start -->*/}
					
					{/*<!-- portfolio items end -->*/}
				
				
				</div>
			</div>
		</div>
	</section>
    );
}
export default Gallery;












