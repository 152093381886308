import React, { useEffect, useState } from 'react';

//visible header that contains LOGO and Navigation Icon
import {PaystackButton} from "react-paystack";



 function PaymentHub({data,price,amount,Submit, email =data.pemail}) {
 const finaldata = data 
     // PAYSTACK INTEGRATION

  // SUCCESSFULLY PAID
  const handlePaystackSuccessAction = async(reference) => {
    
    console.log("Payment succesful");
    // console.log(reference);
    await Submit(reference,data)


  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    
    

  };


  const config = {
    reference: (new Date()).getTime().toString(),
    currency: "GHS",
    email: email ? email : data.pemail,
    amount: 100*amount,
    publicKey: "pk_live_2ecef1c81a94e50860200c001677efee909e0a96",
    // publicKey: "pk_test_c926b8bee36c5a4ebd43aa6b68003106cfcee1b8",

  };

  const componentProps = {
    ...config,
    text: "Pay GH₵"+amount,
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: () => handlePaystackCloseAction(),

  };


  
  return(
        
        
          <div className='my-auto' >
           <h6 className="text-info">Make payment here to complete the process</h6>
              <PaystackButton 
                  className="paystack-button mx-auto px-3"
                  {...componentProps}
                />
          </div>
      
    );
}
export default PaymentHub;












