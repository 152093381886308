import React, { useRef, useState } from "react";
// router dom
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import { useAuth } from "../../../contexts/AuthContext";

// css import
import "../Admin/UserAuth/Auth.css";

// boostraP IMPOTS
import { Container, Row, Col, Button, Form, Alert, Tabs, Tab, ListGroup } from "react-bootstrap";
import { collection, doc, getDoc, query, where,toDate } from "firebase/firestore";
import { db } from "../../firebase";
import { format, isPast, parseISO } from "date-fns";
import RenewHubUserForm from "../../Component/Forms/renewHubForm";
// import { useAuth } from "../../../Component/Context/AuthContext";
// import { useAuth } from "../../../../Components/Context/AuthContext";
import { useHubContext } from "../../Component/Context/HubContext";
import { LinkButtonNS } from "../Admin/Dashboard/DashboardElement";
// image imports
// import Image from "../../../images/img-2.png";
// import { TutorAuthHeader } from "./TutorAuthHeader";


// header import

export default function HubDasboard() {
  const idRef = useRef();
 const {setCurrentHub,currentHub} = useHubContext();
  const [error, setError] = useState("");
  const [data, setData] = useState(currentHub);
  const [loading, setLoading] = useState(false);
  const [key,setKey] = useState('home')
  // const history = useNavigate();

  let location = useLocation();

  // Date variables
  let dateExpired = new Date(data.expiryDate.toDate().toString());


  // let { from } = location.state || { from: { pathname: "/dashboard" } };


  async function handleSubmit(e) {
    e.preventDefault();

   
    const fetchstudentsData =async(classData)=>{
      console.log("--->",classData.current.value );
          setLoading(true)
          let q = ''
       
             q = doc(db, "Hubstudents",classData.current.value );
             

         //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
             const  docSnap = await getDoc(q);
         
             if (docSnap.exists()) {
              console.log("Document data:", docSnap.data());
              setData(docSnap.data());
              setCurrentHub(docSnap.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              setError("No such document!")
            }

         
         //  splitsession(classesdata);
         setLoading(false)

        }

    try {
      setError("");
      setLoading(true);

        // await login(emailRef.current.value, passwordRef.current.value);
        // FETCH DATA OF USER
        fetchstudentsData(idRef)
        console.log("completed login, going to verify")

      //               await verifyUser();
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }
  return (
    <Container fluid>
      <Row>
        {" "}

      <Container >
        <Row>
          <Col sm={12} md={9} lg={8} className="mx-auto mb-2">
          <Tabs 
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
             className="mb-5">
            <Tab eventKey="home" title="Profile">
            <h6  className={isPast(dateExpired) ? 'text-danger text-center' : 'text-info text-center'} >
                Your current membership {isPast(dateExpired) ? 'expired' : 'expires'}  on {format(dateExpired,"do-MMMM-yyyy")}
                <br/>
                {isPast(dateExpired) &&  <button className="btn btn-info text-light jnow mx-auto my-3" onClick={()=>setKey('renew')}>Renew</button>}
              </h6>
              <ListGroup variant="flush">
                <ListGroup.Item>Name : {data.firstname}  {data.othername} {data.lastname}</ListGroup.Item>
                <ListGroup.Item>User ID : {data.id}</ListGroup.Item>
                
                <ListGroup.Item>Hub Since - {new Date(data.createdAt.toDate().toString()).toDateString("en-US")}</ListGroup.Item>
                
                <ListGroup.Item  className="text-capitalize fw-bolder bg-info text-white text-center">Current Subscription</ListGroup.Item>
                <ListGroup.Item>Start Date - {new Date(data.startDate.toDate().toString()).toDateString("en-US")}</ListGroup.Item>
                <ListGroup.Item className={isPast(dateExpired) ? 'bg-danger text-white' : 'bg-warning'} >Expiry Date - {new Date(data.expiryDate.toDate().toString()).toDateString("en-US")}</ListGroup.Item>
                
              </ListGroup>
            </Tab>
            <Tab eventKey="renew" title="Renew Subscription">
              <RenewHubUserForm details={data}/>
            </Tab>
            <Tab eventKey="logout" title="Logout" className="text-center">
              <button className="btn btn-info text-light jnow mx-auto my-3" onClick={()=>setCurrentHub('')}>Logout</button>
            </Tab>
           
          </Tabs>
        </Col>
        </Row>
      </Container> 
     
      </Row>
    </Container>
  );
}
