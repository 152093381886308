import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from "./AuthContext";
import { useHubContext } from './HubContext';


const HubPublicRoute = ({children, restricted}) => {
  const { currentHub } = useHubContext();

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        
            currentHub && restricted ?
                <Navigate to="/hub-dashboard" />
            : children
    );
};

export default HubPublicRoute;