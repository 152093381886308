import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useTestContext } from './TestContext';


const TestPublicRoute = ({children, restricted}) => {
  const { currentTest } = useTestContext();

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        
            currentTest && restricted ?
                <Navigate to="/tests/dashboard" />
            : children
    );
};

export default TestPublicRoute;