import React, { useState, useEffect } from "react";
import { getClassList, getStudentsByClass, deleteStudent } from "../../../Component/Hooks/getSatClass";
import ViewModal from "../../../modal/viewModal";
import AddSATUserFormAdmin from "../../../Component/Forms/addSATUserFormAdmin";
import { Button, Form, Row, Col, Container, Table, Modal } from "react-bootstrap";
import { useAuth } from "../../../Component/Context/AuthContext";

const SATClassManager = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [students, setStudents] = useState([]);
  const [show2, setShow2] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const {currentUser} = useAuth();

  useEffect(() => {
    async function fetchClasses() {
      const classList = await getClassList();
      setClasses(classList);
    }
    fetchClasses();
  }, []);

  useEffect(() => {
    async function fetchStudents() {
      if (selectedClass) {
        const studentList = await getStudentsByClass(selectedClass);
        setStudents(studentList);
      }
    }
    fetchStudents();
  }, [selectedClass]);

  // Function to show student modal
  const handleViewStudent = (student) => {
    setSelectedStudent(student);
    setShowStudentModal(true);
  };

  // Function to delete a student
  const handleDeleteStudent = async () => {
    if (selectedStudent) {
      await deleteStudent(selectedStudent.id);
      setShowStudentModal(false); // Close the modal after deletion
      // Refresh student list after deletion
      const updatedStudents = await getStudentsByClass(selectedClass);
      setStudents(updatedStudents);
    }
  };

  return (
    <Container>
      <h1 className="text-center mt-4">SAT Class Manager</h1>

      <Row className="justify-content-center mt-4">
        <Col md={6}>
          <Form.Group controlId="classSelect">
            <Form.Label>Select Class</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelectedClass(e.target.value)}
              className="mb-3"
            >
              <option value="">Select</option>
              {classes.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.session} - {cls.id}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button
            className="btn btn-warning w-100"
            onClick={() => setShow2(true)}
          >
            Add SAT Student
          </Button>

          <ViewModal
            handleClose={() => setShow2(false)}
            show={show2}
            button="close"
            title="Adding SAT Student"
          >
            <Row>
              <AddSATUserFormAdmin
                type={selectedClass}
                closeModal={() => setShow2(false)}
              />
            </Row>
          </ViewModal>
        </Col>
      </Row>

      {selectedClass && (
        <Row className="mt-5">
          <Col>
            <h2 className="text-center">Students in {selectedClass}</h2>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student.id}>
                    <td>{student.firstname}</td>
                    <td>{student.lastname}</td>
                    <td>{student.email}</td>
                    <td>{student.contact}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleViewStudent(student)}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {/* Modal for viewing individual student details */}
      <Modal show={showStudentModal} onHide={() => setShowStudentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Student Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedStudent && (
            <div>
              <p><strong>First Name:</strong> {selectedStudent.firstname}</p>
              <p><strong>Last Name:</strong> {selectedStudent.lastname}</p>
              <p><strong>Email:</strong> {selectedStudent.email}</p>
              <p><strong>Contact:</strong> {selectedStudent.contact}</p>
              <p><strong>Other Name:</strong> {selectedStudent.othername}</p>
              <p><strong>Parent Name:</strong> {selectedStudent.pname}</p>
              <p><strong>Parent Email:</strong> {selectedStudent.pemail}</p>
              <p><strong>Parent Contact:</strong> {selectedStudent.pcontact}</p>
              <p><strong>Date of Birth:</strong> {selectedStudent.dob}</p>
              <p><strong>Nationality:</strong> {selectedStudent.nationality}</p>
              <p><strong>Session:</strong> {selectedStudent.session}</p>
              {/* <p><strong>Reference:</strong> {selectedStudent.reference}</p> */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {currentUser && <Button variant="danger" onClick={handleDeleteStudent}>
            Delete
          </Button>}
          <Button variant="secondary" onClick={() => setShowStudentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SATClassManager;
