import React ,{ useState ,useEffect} from "react";
import {db,timestamp} from '../../firebase';
import { addDoc, arrayUnion, setDoc, doc, getDoc, increment, runTransaction,updateDoc,Timestamp } from "@firebase/firestore";
import Hashids from 'hashids'
import { stringify } from "@firebase/util";


async function AddHub(data,branch,reference){
    const hashids = new Hashids('',5)
    let error = ''
    let success='';
    let code="";
    
    
    
        //references
        const createdAt = timestamp;
        
        // Create a reference to the SF doc.
        var branchRef = doc(db,"branches",branch.id)
        var logRef = doc(db,"log",'all')
        var countRef = doc(db,"MC","count")
       

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(branchRef);
                const countDoc = await transaction.get(countRef);

                if (!sfDoc.exists()) {
                  throw "Class does not exist!";
                }

                var newCount = (countDoc.data().HubNumber||0) + 1;
                console.log(newCount)
                code=hashids.encode(newCount); 
            
                transaction.update(branchRef, { 
                    hubcount:increment(1),
                   hubs: arrayUnion(code)
                 });
                transaction.update(countRef, { 
                  HubNumber:newCount,
                 });
                transaction.set(doc(db,"hub",code), { 
                    ...data,
                    locationId:branch.id,
                    closed: true, // for testing sake
                    id:code,
                    class:[],
                    location:branch,
                    minage:parseInt(data.minage),
                    createdAt
                 });
                

              });
              // console.log(timestamp.fromDate(data.date))
              console.log("Hub Created successfully!");
              success = true;
            
            } catch (e) {
              console.log("Registration failed: ", e);
              error = "Registration failed! ";
             
              setDoc(doc(db,"log",data.date.replace(/\\/g, '')), {
                createdAt,
                log: "Registration failed",
                details : stringify(e),
                ...data

            });
            }

       
    return {error,success}

}

export default AddHub;