import React, { useEffect, useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col,Button, Spinner } from 'react-bootstrap';

import ViewModal from '../../modal/viewModal';
import AddUserForm from '../../Component/Forms/addUserForm'
// import Button from '@restart/ui/esm/Button';

import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import SessionCard from '../../Component/SessionCard';
import { courses, programId } from '../../coursedata';
import SessionDivs from './SessionDivs';





 function VirtualRealitySessions() {
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [loading,setLoading] = useState(false);
  const [sect, setSect] = useState()
 

  useEffect(()=>{
      const fetchData =async()=>{
        setLoading(true)

      let classesdata = []
      const q = query(collection(db, "courses"), where("programId", "==", programId.vr));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      setLoading(false)
      }

        fetchData();
  },[])


  
  return(
        
        
<section className="sessions pricing virtualReality   py-5 bg-warning">
  <div className="container">
  <div className="heading text-end">
  <span class="badge  bg-info badge-warning badge-pill ">New</span>
  <h1 className="title text-end text-white" id="pricing">Virtual Reality Experiences </h1>
          <div className="separator"></div>
           
            <br/>	
          </div> 
    <div className="row">
    
    <div className='col-md-4 m-auto '>

        {
          sect && sect.map ( (element, i ) => {
            return   <SessionDivs className="bg-transparent" type="vr" loading  sect title=''/>

            })

        }
        {
            (loading && !sect)   &&  <div className="mx-auto text-center" >
              <Spinner className="mx-auto" animation="border" variant="info"/> 
              <p>Loading Available Sessions</p>
              </div>

        }
    </div>
    <div className='col-md-6 text-end mx-0  my-auto ' >
        <p className='text- lead text-white mb-2'>
        Let your kids step into a world of thrill through our virtual reality experience which is set to leave them entertained, educated and physically active. 
        </p>
        <p className='text- lead text-white mb-2'>
        Virtual Reality has proven to be a sure way to increase cognitive flexibility and concentration. From gaming experiences, to educational apps and videos, space exploration, tourism and so much more.
        <br/> Quick! Book a session for your ward, and watch their excitement peak as they step into an immersive VR experience.
        <br/>
        </p>
       
    </div>  
    </div>
  </div>
</section>
    );
}
export default VirtualRealitySessions;












