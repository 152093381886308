import React, { useEffect, useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col,Button, Spinner, DropdownButton,Dropdown } from 'react-bootstrap';

import ViewModal from '../../modal/viewModal';
import AddUserForm from '../../Component/Forms/addUserForm'
// import Button from '@restart/ui/esm/Button';

import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import SessionCard from '../../Component/SessionCard';
import SessionDivs from './SessionDivs.js';
import { courses,programId } from '../../coursedata';





 function Sessions() {
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [loading,setLoading] = useState(false);
  const [sect, setSect] = useState()
  const [ location,setLocation] = useState()
  const [ activeId,setActiveId] = useState()
  const [data, setData] = useState()
  const [vdata, setVData] = useState()
  const [sdata, setSData] = useState()
  const [jdata, setJData] = useState()
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.programId === programId.coding;
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.programId === programId.coding;
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

//  const fetchData =async(locationId)=>{
//    setActiveId(locationId)
//         setLoading(true)

//         let classesdata = []
//        const q = query(collection(db, "classes"), where("closed", "==", false),where('locationId',"==",locationId.id));
// //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
//       const  querySnapshot = await getDocs(q);
//       querySnapshot.forEach((doc) => {
//         // doc.data() is never undefined for query doc snapshots
//         console.log(doc.id, " => ", doc.data());
//         classesdata.push(doc.data())
//       });
      
//       setSect(classesdata);
//       splitsession(classesdata);
//       setLoading(false)
//       }
 

//   useEffect(()=>{
//      const fetchLocationData =async()=>{
//         setLoading(true)

//         let locationData = []
//        const q = query(collection(db, "branches"), where("closed", "==", false));
// //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
//       const  querySnapshot = await getDocs(q);
//       querySnapshot.forEach((doc) => {
//         // doc.data() is never undefined for query doc snapshots
//         console.log(doc.id, " => ", doc.data());
//         locationData.push(doc.data())
//       });
//       if (locationData.length>1){
//         setLocation(locationData);
//       }else{
//         fetchData(locationData[0])
//         setLocation(locationData);

//       }
      
//       // splitsession(classesdata);
//       setLoading(false)
//       }
//         // fetchLocationData();
//   },[])


  
  return(
        
        
<section className="sessions pricing robotics py-5 bg-info">
  <div className="container">
  <div className="heading">
            <h1 className="text-center title text-white" id="pricing">Enroll in the NexBOT programme </h1>
          <div className="separator"></div>
            <p className="lead text-center text-white">
            The NexBOT programme spans a period of <span className='text-warning fw-bolder'>8 weeks</span>  and runs only on Saturdays.
            </p>

          </div> 
        
      <Row>
          {/* SENIOR ROBOTICS SESSIONS */}     
          <Col>
          { <SessionDivs type="senior" location={location} loading  sect title='Senior Robotics Sessions (9+ years )'/>}
          </Col>
          {/* JUNIOR ROBOTICS SESSIONS */}
          <Col>
          { <SessionDivs type="junior"  loading  sect title='Junior Robotics Sessions (5+ years )'/>}
          </Col>
        
      </Row>
    {/* VIRTUAL CODING CLASSES */}
    {vdata && <SessionDivs data={vdata} location={activeId} loading  sect title='Virtual Coding Sessions (6+ years )'/>}

    
    
    
  </div>
</section>
    );
}
export default Sessions;












