import React, { useEffect, useState } from 'react';
import { Row, Spinner, Col, Container, Button, Image, Alert, Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { Body, HeaderBanner, HeaderBannerIcon } from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png";
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../Component/Context/AuthContext';
import ViewModal from '../../../modal/viewModal';
import AddClassForm from '../../../Component/Forms/addClassForm';
import AddHubForm from '../../../Component/Forms/addHubForm';
import UpcomingClasses from './UpcomingClasses';
import ClosedClasses from './ClosedClasses';
import HubSessions from './HubSessions';
import ClosedHubSessions from './ClosedHubSessions';
import MakeBranchLive from '../../../Component/Hooks/useMakeBranchLive';
import CloseBranch from '../../../Component/Hooks/useCloseBranch';

const SingleBranch = () => {
  const [electionData, setElectionData] = useState([]);
  let params = useParams();
  const locationId = params.locationId;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { userID, currentUser, userProfile } = useAuth();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [location, setLocation] = useState();
  const [activeId, setActiveId] = useState();
  const [key, setKey] = useState('class');
  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState([]);
  const defaultCourseId = "J73PEPHiUZbwWBjkihfA"; // Default to robotics

  const fetchLocationData = async () => {
    setLoading(true);
    try {
      const docSnap = await getDoc(doc(db, "branches", locationId));
      if (docSnap.exists()) {
        setLocation(docSnap.data());
      } else {
        setError('Branch Not Found!');
      }
    } catch (err) {
      console.error("Error fetching location data:", err);
      setError('Failed to load branch data');
    }
    setLoading(false);
  };

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const coursesData = [];
      const q = query(collection(db, "courses"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        coursesData.push({ id: doc.id, ...doc.data() });
      });
      setCourses(coursesData);
    } catch (err) {
      console.error("Error fetching courses:", err);
      setError('Failed to load courses data');
    }
    setLoading(false);
  };

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const classesData = [];
      const q = query(collection(db, "classes"), where("locationId", "==", locationId));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        classesData.push({ id: doc.id, ...doc.data() });
      });
      // Assign default course ID to classes without a course ID
      classesData.forEach(cls => {
        if (!cls.courseId) {
          cls.courseId = defaultCourseId;
        }
      });
      setClasses(classesData);
    } catch (err) {
      console.error("Error fetching classes:", err);
      setError('Failed to load classes data');
    }
    setLoading(false);
  };

  const GoLive = async () => {
    setLoading(true);
    const { error, success } = await MakeBranchLive(locationId);
    setError(error);
    setSuccess(success);
    setLoading(false);
    fetchLocationData();
  };

  const GoOffline = async () => {
    setLoading(true);
    const { error, success } = await CloseBranch(locationId);
    setError(error);
    setSuccess(success);
    setLoading(false);
    fetchLocationData();
  };

  const FilterByCourseIdAndClosedStatus = (courseId, isClosed) => {
    if (!classes) return [];
  
    console.log("Filtering classes for courseId:", courseId);
    console.log("Classes data:", classes);
  
    const filteredClasses = classes.filter(cls => {
      if (!cls.courseId) {
        cls.courseId = defaultCourseId;
      }
      console.log(`Checking class with ID ${cls.id}:`, cls.courseId, cls.closed);
      return cls.courseId === courseId && cls.closed === isClosed;
    });
  
    console.log(`Filtered classes for courseId ${courseId} and closed status ${isClosed}:`, filteredClasses);
    return filteredClasses;
  };
  
  

  useEffect(() => {
    fetchLocationData();
    fetchCourses();
    fetchClasses();
  }, [locationId]);

  const handleTabSelect = (courseId) => {
    setKey(courseId);
  };

  // Debug logging
  useEffect(() => {
    if (courses.length > 0) {
      setKey(courses[0].id);
    };
  }, [courses]);

  return (
    <Body>
      <Container className="m-2 mx-auto align-content-sm-center container-fluid">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard/branches" }}>Branches</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/dashboard/branches/${locationId}` }} active>Single Branch</Breadcrumb.Item>
        </Breadcrumb>
        <HeaderBanner className="row mx-auto my-auto p-2">
          <HeaderBannerIcon md={2} lg={2}>
            <Image src={logo} rounded fluid />
          </HeaderBannerIcon>
          <Col md={6} lg={4}>
            <h3>{location && location.name}</h3>
            <p>{location && location.location}</p>
          </Col>
          <Col md={3} lg={1} className="text-end mx-auto my-auto">
            {location &&
              (!(location.closed) ? <Button onClick={() => GoOffline()} >Go Offline</Button> : <Button onClick={() => GoLive()}  >Go Live</Button>)}
          </Col>
          <Col md={3} lg={1} className="text-end mx-auto my-auto">
            <Button onClick={() => setShow1(true)} className="my-auto btn my-auto mx-auto"><BsPlus /> Add Class</Button>
          </Col>
          <ViewModal
            handleClose={() => setShow1(false)}
            show={show1}
            button="close"
            title="Add Class">
            <div className="row">
              <AddClassForm branch={location} />
            </div>
          </ViewModal>
          <Col md={3} lg={1} className="text-end mx-auto my-auto">
            <Button onClick={() => setShow2(true)} className="my-auto btn my-auto mx-auto"><BsPlus /> Add Hub</Button>
          </Col>
          <ViewModal
            handleClose={() => setShow2(false)}
            show={show2}
            button="close"
            title="Add Hub">
            <div className="row">
              <AddHubForm branch={location} />
            </div>
          </ViewModal>
        </HeaderBanner>
        {loading && <Spinner animation="grow" variant="success" />}
        {error && <Alert variant="danger">{error}</Alert>}
        <Row>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleTabSelect(k)}
            className="mb-5">
            {courses.map((course) => (
              <Tab eventKey={course.id} title={course.name} key={course.id}>
                <UpcomingClasses classes={FilterByCourseIdAndClosedStatus(course.id,false)} />
                <ClosedClasses classes={FilterByCourseIdAndClosedStatus(course.id,true)} />
              </Tab>
            ))}
          
          </Tabs>
        </Row>
      </Container>
    </Body>
  );
};

export default SingleBranch;
