import React, { useEffect, useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Coding.css";
import { Container, Row,Card,Col,Button, Spinner, DropdownButton,Dropdown } from 'react-bootstrap';

import ViewModal from '../../modal/viewModal';
import AddUserForm from '../../Component/Forms/addUserForm'
// import Button from '@restart/ui/esm/Button';

import { doc, getDoc,collection, query, where, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import SessionCard from '../../Component/SessionCard';
import SessionDivs from './SessionDivs.js';
import { courses,programId } from '../../coursedata';
import SessionVirtualCard from '../../Component/SessionVirtualCard';





 function SessionsCoding() {
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [loading,setLoading] = useState(false);
  const [sect, setSect] = useState()
  const [ courses,setCourse] = useState()
  const [ activeId,setActiveId] = useState()
  const [data, setData] = useState()
  const [vdata, setVData] = useState()
  const [sdata, setSData] = useState()
  const [jdata, setJData] = useState()
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.programId === programId.coding;
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.programId === programId.coding;
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

  useEffect(()=>{
    const fetchCourseData =async()=>{
       setLoading(true)

       let courseData = []
      const q = query(collection(db, "courses"), where("closed", "==", false), where("programId", "==", programId.coding));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
     const  querySnapshot = await getDocs(q);
     querySnapshot.forEach((doc) => {
       // doc.data() is never undefined for query doc snapshots
       console.log(doc.id, " => ", doc.data());
       courseData.push(doc.data())
     });
     if (courseData.length>1){
       setCourse(courseData);
     }else{
       setCourse(courseData);
     }
     
     // splitsession(classesdata);
     setLoading(false)
     }
       fetchCourseData();
 },[])

  
  return(
        
        
<section className="session codingprice coding py-5 bg-dark">
  <div className="container">
  <div className="heading">
            <h1 className="text-center title text-white" id="codingprice">Enroll in the NexIT Coding programme </h1>
          <div className="separator"></div>
            <p className="lead text-center text-white">
            The NexIT coding programmes span a period of <span className='text-warning font-weight-bold'>16 weeks</span> and runs only on Saturdays.
            </p>

          </div> 
         
      <Row>          
          {courses && courses.map ( (element, i ) => {
          return (<Col md={6} lg={6}>
                            <SessionVirtualCard className="Col-md-6"
                            courseDetail={element}
                              ukey = {2}
                              courseId ={element.Id}
                            />
                            </Col> )})}
      
      </Row>

    
    
    
  </div>
</section>
    );
}
export default SessionsCoding;












