import React, { useEffect, useRef, useState } from "react";
// router dom
import { Link, useNavigate ,useLocation} from "react-router-dom";
// import { useAuth } from "../../../contexts/AuthContext";

// css import
import "../Admin/UserAuth/Auth.css";

// boostraP IMPOTS
import { Container, Row, Col, Button, Form, Alert, Tabs, Tab, ListGroup } from "react-bootstrap";
import { collection, doc, getDoc, query, where,toDate } from "firebase/firestore";
import { db } from "../../firebase";
import { parseISO } from "date-fns";
import RenewHubUserForm from "../../Component/Forms/renewHubForm";
// import { useAuth } from "../../../Component/Context/AuthContext";
// import { useAuth } from "../../../../Components/Context/AuthContext";
import { useHubContext } from "../../Component/Context/HubContext";
import ViewModal from "../../modal/viewModal";
import AddHubUserForm from "../../Component/Forms/addHubUserForm";
import Hubslider from "../../Component/Helpers/HubSlider";
// image imports
// import Image from "../../../images/img-2.png";
// import { TutorAuthHeader } from "./TutorAuthHeader";


// header import

export default function Hublogin() {
  const idRef = useRef();
 const {setCurrentHub,currentHub} = useHubContext();
  const [error, setError] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [show1,setShow1] = useState(false);
  // const history = useNavigate();

  let location = useLocation();

  // let { from } = location.state || { from: { pathname: "/dashboard" } };


  async function handleSubmit(e) {
    e.preventDefault();

   
    const fetchstudentsData =async(classData)=>{
      console.log("--->",classData.current.value );
          setLoading(true)
          let q = ''
       
             q = doc(db, "Hubstudents",classData.current.value );
             

         //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
             const  docSnap = await getDoc(q);
         
             if (docSnap.exists()) {
              console.log("Document data:", docSnap.data());
              setData(docSnap.data());
              setCurrentHub(docSnap.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              setError("No such document!")
            }

         
         //  splitsession(classesdata);
         setLoading(false)

        }

    try {
      setError("");
      setLoading(true);

        // await login(emailRef.current.value, passwordRef.current.value);
        // FETCH DATA OF USER
        fetchstudentsData(idRef)
        console.log("completed login, going to verify")

      //               await verifyUser();
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Row>
        {" "}

     
        <Col  className="loginsideimg-S">
          <Container fluid className="my-auto my-auto">
            
            <Row className="height-full-S">
              <Col sx={11} sm={6} md={4} lg={3}  className="mx-auto my-auto text-center container">
               
                <h5 className="text-primary m-4">Welcome to the hub!</h5>
                <p>Please enter your ID in the space below</p>
               
               <div></div>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
               
                  <Form.Group>
                    <Form.Control
                      className="form-input my-2 text-center"
                      type="text"
                      placeholder="XXXXX"
                      ref={idRef}
                      required
                    />
                  </Form.Group>
                  
                  
                  <Button
                    disabled={loading}
                    variant="primary"
                    className="m-auto w-100"
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Form>
                <p className="head text-muted p-1 mx-auto d-block">
                Don't have an account yet? 
                {/* <Link to="../#hub" className="text-success "> Sign up here.</Link> */}
                <hr/>
                
                <button onClick={()=>setShow1(true)} className='btn btn-dark jnow mx-auto '>Join the hub</button>
                </p>
              </Col>
              <ViewModal
												handleClose={() => setShow1(false)}
												show={show1}
												button="close"
												title={"Hub Registration"}>
												<div className="row">	
												<AddHubUserForm />
											</div>
											</ViewModal>
              
            </Row>
            <Hubslider/>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
