import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Table, Form, Alert } from "react-bootstrap";
import { db } from "../../../firebase";
import { collection, getDocs, addDoc, deleteDoc, doc } from "@firebase/firestore";
import { useAuth } from "../../../Component/Context/AuthContext";

export default function StaffManagement() {
  const [staffList, setStaffList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [error, setError] = useState("");
  const {currentUser} = useAuth()
  const nameRef = useRef();
  const codeRef = useRef();

  // Fetch staff list from the database
  useEffect(() => {
    async function fetchStaffList() {
      const staffCollectionRef = collection(db, "Staff");
      const staffSnapshot = await getDocs(staffCollectionRef);
      const staffData = staffSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStaffList(staffData);
    }
    fetchStaffList();
  }, []);

  // Function to handle adding new staff
  const handleAddStaff = async () => {
    try {
      const newStaff = {
        name: nameRef.current.value,
        id: nameRef.current.value,
        code: codeRef.current.value,
      };
      await addDoc(collection(db, "Staff"), newStaff);
      setStaffList((prevList) => [...prevList, newStaff]);
      setShowAddModal(false);
      setError("");
    } catch (error) {
      setError("Error adding staff: " + error.message);
    }
  };

  // Function to handle viewing staff details
  const handleViewDetails = (staff) => {
    setSelectedStaff(staff);
    setShowDetailsModal(true);
  };

  // Function to delete staff
  const handleDeleteStaff = async (staffId) => {
    try {
      await deleteDoc(doc(db, "Staff", staffId));
      setStaffList(staffList.filter((staff) => staff.id !== staffId));
      setShowDetailsModal(false);
    } catch (error) {
      console.error("Error deleting staff: ", error);
    }
  };

  return (
    <div className="container">
      <h3>Staff Management</h3>
      <Button onClick={() => setShowAddModal(true)}>Add New Staff</Button>
      
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Code</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {staffList.map((staff, index) => (
            <tr key={staff.id}>
              <td>{index + 1}</td>
              <td>{staff.name}</td>
              <td>{staff.code}</td>
              <td>
                <Button variant="info" onClick={() => handleViewDetails(staff)}>
                  View Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Add Staff Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" ref={nameRef} placeholder="Enter name" required />
            </Form.Group>
            <Form.Group controlId="formCode" className="mt-3">
              <Form.Label>Code</Form.Label>
              <Form.Control type="text" ref={codeRef} placeholder="Enter code" required />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddStaff}>
            Add Staff
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Staff Details Modal */}
      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Staff Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedStaff && (
            <>
              <p><strong>Name:</strong> {selectedStaff.name}</p>
              <p><strong>Code:</strong> {selectedStaff.code}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
         {currentUser && <Button variant="danger" onClick={() => handleDeleteStaff(selectedStaff.id)}>
            Delete Staff
          </Button>}
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
