import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Breadcrumb, Badge} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useHistory, useParams ,Link} from 'react-router-dom';
// import { BecomeProWidget } from '../../../components/BecomeProWidget';
// import ClassList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from './DashboardElement';
import logo from "../../../assets/img/logo.png"
import { useAuth } from '../../../Component/Context/AuthContext';
import ViewModal from '../../../modal/viewModal';
import AddClassForm from '../../../Component/Forms/addClassForm';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import ClassList from '../../../Component/Table/Table';
import AddVirtualClassForm from '../../../Component/Forms/addVirtualClassForm';


const Dashboard =()=>{

    const [electionData,setElectionData] = useState([])
    const [success,setSuccess] = useState(false);
    const [show,setShow] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID,currentUser,userProfile} = useAuth()

    const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const [show3,setShow3] = useState(false);
  const [sect, setSect] = useState()
  const [students, setStudents] = useState()
  const [ location,setLocation] = useState()
  const [ courses,setCourse] = useState()
  const [ activeId,setActiveId] = useState()
  const [data, setData] = useState()
  const [vdata, setVData] = useState()
  const [sdata, setSData] = useState()
  const [jdata, setJData] = useState()
 

  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

 const fetchLocationClassesData =async(locationId)=>{
   setActiveId(locationId)
        setLoading(true)

        let classesdata = []
       const q = query(collection(db, "classes"), where("closed", "==", false),where('locationId',"==",locationId.id));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      splitsession(classesdata);
      setLoading(false)
      }

      const fetchclosedClassesData =async(locationId)=>{
        setActiveId(locationId)
             setLoading(true)
     
             let classesdata = []
            const q = query(collection(db, "classes"), where("closed", "==", true), where("locationId","==",locationId.id));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             classesdata.push(doc.data())
           });
           
           setSect(classesdata);
           splitsession(classesdata);
           setLoading(false)
           }
 

  useEffect(()=>{
    const fetchAllClassesData =async(locationId)=>{
        setActiveId(locationId)
             setLoading(true)
     
             let classesdata = []
            const q = query(collection(db, "classes"));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             classesdata.push(doc.data())
           });
           
           setSect(classesdata);
           splitsession(classesdata);
           setLoading(false)
           }
    const fetchAllStudents =async(locationId)=>{
        setActiveId(locationId)
             setLoading(true)
     
             let stiduentsData = []
            const q = query(collection(db, "students"));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             stiduentsData.push(doc.data())
           });
           
           setStudents(stiduentsData);
           setLoading(false)
           }

     const fetchLocationData =async()=>{
        setLoading(true)

        let locationData = []
       const q = query(collection(db, "branches"));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        locationData.push(doc.data())
      });
      
      setLocation(locationData);
      // splitsession(classesdata);
      setLoading(false)
      }
     const fetchCoursesData =async()=>{
        setLoading(true)

        let coursesData = []
       const q = query(collection(db, "courses"));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        coursesData.push(doc.data())
      });
      
      setCourse(coursesData);
      // splitsession(classesdata);
      setLoading(false)
      }
        fetchLocationData();
        fetchCoursesData();
        fetchAllClassesData();
        fetchAllStudents();

  },[])


    const closeModal =()=>{
        setShow(false)
        // FetchProduct()
      }

    
    

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }} active>Admin Dashboard</Breadcrumb.Item>
                
                
            </Breadcrumb>
             <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={(userProfile && userProfile.photoUrl )|| logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                        <h5>Welcome {userProfile && userProfile.firstName}</h5>
                        <p>Create and Manage your classes from here</p>
                    </Col>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <Button className="addbutton my-auto btn my-auto mx-auto" onClick={() => setShow(true)}>
                           <BsPlus/>  New Class
                            </Button>
                            <ViewModal
                            title="Add Product"
                            handleClose={closeModal}
                            show={show}
                            button="Add Product"
                            >
                            <AddVirtualClassForm closeModal={closeModal} />
                            </ViewModal>
                    </Col>
             </HeaderBanner>
             <Row>
             <div className='col'>
              <Link to={"staffs"} className ={'btn btn-primary fw-bolder text-uppercase m-2 '} >Manage Staff  
             </Link>
             </div>
              
             </Row>
             

             

             {/* activity summary bar */}
             <Row className="bg-white p-2 m-auto mx-auto mt-2">
             <Col className="bg-white m-auto mx-auto mt-2">
                 
             <Card  className=" border-success p-4">
                     <Row>
                        <Col md={6} lg={8}><h1>{location && location.length}</h1> Branches</Col>
                        <Col className="text-end mx-auto my-auto">
                            <LinkButton to="branches" className="btn  btn-small">View</LinkButton>
                        </Col>
                     </Row>
                 </Card>

             </Col>
            
            <Col className="bg-white m-auto mx-auto mt-2">
                <Card  className=" border-success p-4">
                     <Row>
                        <Col md={6} lg={8}><h1>{sect && sect.length}</h1> Classes</Col>
                        <Col className="text-end mx-auto my-auto">
                            <LinkButton to="myclasses" className="btn  btn-small">View</LinkButton>
                        </Col>
                     </Row>
                 </Card>
            </Col>
            <Col className="bg-white m-auto mx-auto mt-2">
            <Card  className=" border-success p-4">
                     <Row>
                        <Col md={6} lg={8}><h1>{students && students.length}</h1> Students</Col>
                        <Col className="text-end mx-auto my-auto">
                            <LinkButton to="myclasses" className="btn  btn-small">View</LinkButton>
                        </Col>
                     </Row>
                 </Card>
            </Col>
          </Row>
             {/* table for recent elections */}
           <Row>
            <div>
              <h1>Courses</h1>
              
              {
            courses && courses.map ( (element, i ) => {
              return <Link to={"courses/"+element.id} disabled
              // onClick={()=>fetchLocationClassesData(element)}
              className ={element.id === (activeId && activeId.id)||0 ? 'branch-active text-primary fw-bolder text-uppercase m-2 ':'branch-btn text-primary fw-bolder text-uppercase m-2 '} >{element.name} </Link>
              })
              }
              </div>
             
               
             </Row>
           <Row>
           <h1>SAT</h1>
           <div>
           <Link to={"sat"} className ={'branch-btn text-warning fw-bolder text-uppercase m-2 '} >SAT  <Badge bg="warning" >Live</Badge>
             </Link>
           <Link to={"ielts"} className ={'branch-btn text-warning fw-bolder text-uppercase m-2 '} >IELTS  <Badge bg="warning" >Live</Badge>
             </Link>

           </div>

           <h1>Branches</h1>

          <div>
             {
          location && location.map ( (element, i ) => {
            return <Link to={"branches/"+element.id}
            // onClick={()=>fetchLocationClassesData(element)}
             className ={'branch-btn text-primary fw-bolder text-uppercase m-2 '} >{element.location} 
             {element.closed ? <Badge bg="secondary">Offline</Badge> : <Badge bg="info" >Live</Badge>}
             </Link>
            })
            }
            </div>
            
             
                 <Col>
             {/* {(!loading && !error && jdata) && <ClassList classes={jdata} title='Junior Courses'/>}    */}
                 </Col>
                 <Col>
             {/* {(!loading && !error && sdata) && <ClassList classes={sdata} title='Senior Courses'/>} */}
                 </Col>
             </Row>
             {loading && <Spinner className="text-center my-auto mx-auto" animation="border" variant="success"/>}
             {(error ) && <Alert className="text-center" variant="danger">{error}</Alert>}


        </Container>
    

    </Body>
    )
}


export default Dashboard;