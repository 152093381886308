import React from 'react';
import '../../views/Landing/Hub.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide1 from '../../assets/img/Partners/gh_library.jpg';
import slide2 from '../../assets/img/Partners/OHAAlogo.jpg';
import slide3 from '../../assets/img/Partners/springforth.jpg';
import slide4 from '../../assets/img/Partners/littleexplorersLogo.png';
import slide5 from '../../assets/img/Partners/tvafrica.jpeg';
import slide6 from '../../assets/img/Partners/JCSSLogo.jpg';
import slide7 from '../../assets/img/Partners/JLMLogoSmall.png';
import slide8 from '../../assets/img/Partners/KiselLogo.png';
import slide9 from '../../assets/img/Partners/BloomingsMonPlus.png';
import slide10 from '../../assets/img/Partners/SATdownload.png';
import vire from '../../assets/img/Partners/Vire agency_ black&grey.png';
import skillit from '../../assets/img/Partners/skillitgh.png';
import IELTSlogo from '../BCLOGO/IELTSlogo';

function PartnerSlider() {
  const settings = {
    className: 'center',
    centerMode: true,
    centerPadding: '40px',
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          rows: 1,
          centerPadding: '20px',
        },
      },
    ],
  };

  return (
    <div className="slide-wrapper my-4">
      <Slider {...settings}>
        {[slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9, slide10, vire, skillit].map((img, index) => (
          <div key={index} className="slide-item">
            <div className="image-container d-flex align-items-center justify-content-center">
              <img src={img} alt={`Partner ${index + 1}`} className="img-fluid" />
            </div>
          </div>
        ))}
        <div className="slide-item">
          <div className="image-container d-flex align-items-center justify-content-center">
            <IELTSlogo />
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default PartnerSlider;
