import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb, Tabs, Tab, ListGroup} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
// import { useAuth } from '../../../Components/Context/AuthContext';
// import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
// import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/PartnerDashboardElement';
import logo from "../../../assets/img/logo.png"
import ClassList from '../../../Component/Table/Table';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import ClosedClasses from './ClosedClasses';
import ViewModal from '../../../modal/viewModal';
import AddClassForm from '../../../Component/Forms/addClassForm';
import AddHubForm from '../../../Component/Forms/addHubForm';
import UpcomingClasses from './UpcomingClasses';
import HubSessions from './HubSessions';
import { usePartnerContext } from '../../../Component/Context/PartnerContext';


const PartnerSingleBranch =()=>{
    const [electionData,setElectionData] = useState([])
    let params = useParams();
    const locationId = params.locationId;
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {currentPartner} = usePartnerContext()

    const [show,setShow] = useState(false);
    const [show1,setShow1] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    const [sect, setSect] = useState()
    const [ location,setLocation] = useState()
    const [ activeId,setActiveId] = useState()
    const [data, setData] = useState()
    const [vdata, setVData] = useState()
    const [sdata, setSData] = useState()
    const [jdata, setJData] = useState()
    const [key,setKey] = useState('class')


  const splitsession=(rawdata)=>{
   const inperson =  rawdata.filter(function( obj ) {
    return obj.session !== 'virtual';
  })
   const virtual =  rawdata.filter(function( obj ) {
    return obj.session === 'virtual';
  })
  const seniorinperson =  rawdata.filter(function( obj ) {
  // Senior inperson classes

    return obj.session !== 'virtual' && obj.minage === 9 ;
  })

  const juniorinperson =  rawdata.filter(function( obj ) { 
  // junior inperson classes
    return obj.session !== 'virtual' && obj.minage === 5;
  })
  const wovirtual = inperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const sinperson = seniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  const jinperson = juniorinperson.sort(function(a, b) { 
    return a.order - b.order;
})
  setVData(virtual);
  setData(wovirtual);
  setJData(jinperson);
  setSData(sinperson);

  ;}

 const fetchLocationClassesData =async()=>{
   setActiveId(locationId)
        setLoading(true)

        let classesdata = []
       const q = query(collection(db, "classes"), where("closed", "==", false),where('locationId',"==",locationId.id));
//  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
      const  querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        classesdata.push(doc.data())
      });
      
      setSect(classesdata);
      splitsession(classesdata);
      setLoading(false)
      }

      const fetchclosedClassesData =async()=>{
        setActiveId(locationId)
             setLoading(true)
     
             let classesdata = []
            const q = query(collection(db, "classes"), where("closed", "==", true), where("locationId","==",locationId));
     //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
           const  querySnapshot = await getDocs(q);
           querySnapshot.forEach((doc) => {
             // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             classesdata.push(doc.data())
           });
           
           setSect(classesdata);
           splitsession(classesdata);
           setLoading(false)
           }
 


    useEffect(()=>{
        const fetchAllClassesData =async()=>{
            setActiveId(locationId)
                 setLoading(true)
         
                 let classesdata = []
                 const q = query(collection(db, "classes"), where("closed", "==", false), where("locationId", "==", locationId));
                 //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
               const  querySnapshot = await getDocs(q);
               querySnapshot.forEach((doc) => {
                 // doc.data() is never undefined for query doc snapshots
                 console.log(doc.id, " => ", doc.data());
                 classesdata.push(doc.data())
               });
               
               setSect(classesdata);
               splitsession(classesdata);
               setLoading(false)
               }
    
         const fetchLocationData =async()=>{
            setLoading(true)
    
            let locationData = {}
           const q = doc(db, "branches",locationId);
    //  const docRef = doc(db, "classes","96Nbt4jX1Xwu0TXFUVBn");
          
            const docSnap = await getDoc(q);

            if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            locationData = docSnap.data();
            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setError('Branch Not Found!')
            }
          
          await setLocation(locationData);
          // splitsession(classesdata);
          setLoading(false)
          }
            fetchLocationData();
            // fetchAllClassesData();
    
      },[])
    
    

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/partners/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/partners/dashboard/"+locationId }} active >
                {location && location.name}
                </Breadcrumb.Item>
                
            </Breadcrumb>
            <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                      <h3>{location && location.name}</h3>
                      <p>{location && location.location}</p>
                    </Col>
                    
             </HeaderBanner>
             <Row className='mt-2'>
             <Tabs 
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
             className="mb-5">
           
            <Tab eventKey="class" title="Classes">
              <UpcomingClasses locationId={locationId}/>
              <ClosedClasses locationId={locationId}/>
            </Tab>
            <Tab eventKey="hub" title="Innovators Hubs" className="">
            <HubSessions locationId={locationId}/>

            </Tab>
           
          </Tabs>
             </Row>
             {loading && <Spinner animation="border" variant="info"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}


             
        </Container>
    

    </Body>
    )
}

export default PartnerSingleBranch;