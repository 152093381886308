import React ,{ useState ,useEffect} from "react";
import {db,timestamp} from '../../firebase';
import { addDoc, arrayUnion, setDoc, doc, getDoc, increment, runTransaction,updateDoc,Timestamp } from "@firebase/firestore";
import Hashids from 'hashids'
import { stringify } from "@firebase/util";


async function MakeHubLive(data,branch,type){
    const hashids = new Hashids('',5)
    let error = ''
    let success='';
    let code="";
    
    
    
        //references
        
        // Create a reference to the SF doc.
    
        var classRef = doc(db,"hub",branch.id)
       if (type == "virtual"){ // checks if functions is called for a virtual class
        classRef = doc(db,"vclasses",branch.id)
       }

            try {
              await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(classRef);

                if (!sfDoc.exists()) {
                  throw "Hub does not exist!";
                }

               
            
                transaction.update(classRef, { 
                    closed:false,
                 });
               
               
                

              });
              // console.log(timestamp.fromDate(data.date))
              console.log("Updated successfully!");
              success = true;
            
            } catch (e) {
              console.log("Update failed: ", e);
              error = "Update failed! ";
             
              
            }

       
    return {error,success}

}

export default MakeHubLive;