import React from "react";
import { Navigate, Redirect, Route } from "react-router-dom";
import { usePartnerContext } from "./PartnerContext";

const PartnerPrivateRoute = ({ children }) => {
  const { currentPartner } = usePartnerContext();

  
    return currentPartner ? 
    children 
   : 
      <Navigate to={{
          pathname: "/partners",
        }} /> 
};

export default PartnerPrivateRoute;
