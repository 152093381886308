import React, { useState } from 'react';
import { RiFlashlightLine, RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import {BsDot} from "react-icons/bs"

//visible header that contains LOGO and Navigation Icon
import "./Services.css";
import { Container, Row,Card,Col, } from 'react-bootstrap';


function Services() {
    return(
        
        <section className="section services transprant-bg pclear secPadding">
			<div className="container " data-animation-effect="fadeIn">
				<h1 id="services" className="title text-center">What we offer</h1>
				<div className="space"></div>
				<div className="row">
					<div className="col-md-4">
						<div className="media block-list">
							<div className="media-left">
								<i className="fa fa-gear"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">HANDS-ON TRAINING</h3>
								<blockquote>
									<p>
									Our training is not merely theoretical but offers a practical hands-on approach.									
									</p>
									
								</blockquote>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="media block-list">
							<div className="media-left">
								<i className="fa fa-wifi"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">ONLINE RESOURCES</h3>
								<blockquote>
									<p>
										We draw from a rich repository of online resources in a vast technological climate.
									</p>
									
								</blockquote>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="media block-list">
							<div className="media-left">
								<i className="fa fa-home"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">HOME TUITION</h3>
								<blockquote>
									<p>
										Our home tuition option is designed to bring the experience to your place of convenience.									
									</p>
									
								</blockquote>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="media block-list">
							<div className="media-left">
								<i className="fa fa-desktop"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">ONLINE REGISTRATION</h3>
								<blockquote>
									<p>
									Our flexible online registration and payment options ease our enrolment process.										
									</p>
									
								</blockquote>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="media block-list">
							<div className="media-left">
								<i className="fa fa-certificate "></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">CERTIFICATE OF COMPLETION</h3>
								<blockquote>
									<p>
									We offer certificates to participants upon completion of our programme.									
									</p>
									
								</blockquote>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="media block-list">
							<div className="media-left">
								<i className="fa fa-graduation-cap"></i>
							</div>
							<div className="media-body">
								<h3 className="media-heading">AFTER-SCHOOL PROGRAMME</h3>
								<blockquote>
									<p>
									We partner with schools to bring the Nexbot programme to their doorstep.										
									</p>
									
								</blockquote>
							</div>
						</div>
					</div>
				</div>
			</div>			  
		</section>
    );
}
export default Services;












